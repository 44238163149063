import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@material-ui/core';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------



export default function AppWebsiteVisits(props) {
  const CHART_DATA = [
    {
      name: 'Monthly Registred User',
      type: 'column',
      data: props.props.totaluserbymonth
    },
    {
      name: 'Monthly Ordered Product',
      type: 'area',
      data: props.props.totalproductorderbymonth
    },
    {
      name: 'Monthly Subscribed User',
      type: 'line',
      data: props.props.totalsubsriberbymonth
    }
  ];
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: props.props.monthstartdate,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title="Users ,Subscribers And Product Ordered Data" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
