import { Icon } from '@iconify/react';
import { useRef, useState, React } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate, NavLink as RouterNavLink, useLocation, useParams } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, ListItemIcon, ListItemText, Button, Dialog, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import eyeFill from '@iconify/icons-eva/eye-fill';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import CloseIcon from '@material-ui/icons/Close';
// ----------------------------------------------------------------------

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});



export default function LiveContentMoreMenu(props) {
  const navigate = useNavigate();
  const [premiumContent, setPremiumContent] = useState([]);
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const deleterow = () => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/deleteinterviews`;
    axios.post(Livechaturl, {
      interview_id: props.id,
    },
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      }).then((response) => {
        const outlook = response
        if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
          navigate('/login', { replace: true });
          localStorage.setItem("token", "");
          localStorage.setItem("userid", '0');
          localStorage.setItem("email", '');
          toast.warning(outlook.data.message)
        }
        else {
          window.location.href = '/dashboard/interview';
          // navigate('/dashboard/interview', { replace: true });
          toast.success(outlook.data.message)
        }
      })
  }
  const editrow = () => {
    // alert(JSON.stringify(props.id))
  }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {/* <MenuItem component={RouterNavLink} to={`/dashboard/livecontent/updatelivecontent/${props.id}`}  sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} onClick={handleClickOpen} />
          </ListItemIcon>
        </MenuItem> */}
        <MenuItem component={RouterNavLink} to={`/dashboard/livecontent/details/${props.id}`} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={eyeFill} width={24} height={24} />
          </ListItemIcon>
        </MenuItem>

      </Menu>
      <div>
        <ToastContainer />
      </div>
    </>
  );
}
