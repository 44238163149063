import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link as RouterLink, useNavigate,NavLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  styled
} from '@material-ui/core';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Spinner } from '../components/Spinner/index';


// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, SubscriberMoreMenu } from '../components/_dashboard/optionmore';

//
import USERLIST from '../_mocks_/user';
// import { CenterFocusStrong } from '@material-ui/icons';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user_id', label: 'User Id', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'subscription_id', label: 'Subscriber Id', alignRight: false },
  { id: 'plan_level', label: 'Plan Type', alignRight: false },
  { id: 'stripe_status', label: 'Stripe Status', alignRight: false },
  { id: 'invoice_id', label: 'Invoice Id', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'plan_active', label: 'Plan-Start', alignRight: false },
  { id: 'plan_expired', label: 'Plan-Ent', alignRight: false },
  { id: 'Action', label: 'Action' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.plan_level.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


export default function Subscriber() {
  const navigate = useNavigate();
  const { promiseInProgress } = usePromiseTracker();
  if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
    navigate('/login', { replace: true });
  }
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('user_id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [userContent, setuserContent] = useState([]);

  useEffect(() => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/subscriberlistbyadmin`;
    const bodyFormData = new FormData();
    trackPromise(
      axios.post(Livechaturl, {},
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
          }
        })
        .then((response) => {
          const outlook = response
          if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
            navigate('/login', { replace: true });
            localStorage.setItem("token", "");
            localStorage.setItem("userid", '0');
            localStorage.setItem("user_id", '');
            toast.warning(outlook.data.message)
          }
          else {
            setuserContent(outlook.data.data);
          }
        })
    );
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userContent.map((n) => n.user_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userContent.length) : 0;

  const filteredUsers = applySortFilter(userContent, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/';

  return (
    <Page title="Subscription List | SoundChatRadio">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Subscription List
          </Typography>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            name="Search Subscription By Plan Type"
          />
          {/* <Button
                        variant="contained"
                        component={RouterLink}
                        to="/dashboard/user/adduser"
                        startIcon={<Icon icon={plusFill} />}
                    >
                        Add New User
                        
          </Button> */}
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            Add New User
          </Button> */}
        </Stack>

        <Card>
          {
            (promiseInProgress === true) ?
              <Spinner />
              :
              <>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={userContent.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                      
                        {filteredUsers
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            const { subscription_id,email, user_id,plan_active,plan_expired,plan_level,plan_type,amount,stripe_status,invoice_id  } = row;
                            const isItemSelected = selected.indexOf(user_id) !== -1;

                            return (
                              
                              <TableRow
                                hover
                                key={user_id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                
          
                                <TableCell padding="checkbox">
                                  {/* <Checkbox
                        checked={isItemSelected}
                        onChange={(event) => handleClick(event, id)}
                      /> */}
                                </TableCell>
                                <TableCell >{user_id}</TableCell>
                                <TableCell align="left">{email}</TableCell>
                                <TableCell align="left">
                                  {subscription_id}
                                </TableCell>
                                
                                <TableCell align="left">{plan_level}</TableCell>
                                <TableCell align="left">{stripe_status}</TableCell>
                                <TableCell align="left">{invoice_id}</TableCell>
                                <TableCell align="left">{amount}</TableCell>
                                <TableCell align="left">{moment(plan_active).format('MMMM Do YYYY')}</TableCell>
                                <TableCell align="left">{moment(plan_expired).format('MMMM Do YYYY')}</TableCell>
                                <TableCell align="center">
                                  <SubscriberMoreMenu id={user_id} />
                                </TableCell>
          
                              </TableRow>
                              
                            );
                          })}

                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={userContent.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>

          }
          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}


        </Card>
        <ToastContainer />

      </Container>
    </Page>
  );
}
