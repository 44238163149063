import React, { useState, useEffect } from 'react';

import { useForm, } from 'react-hook-form';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack, Grid, Container, Typography, Avatar,Button
} from '@mui/material';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import plusFill from '@iconify/icons-eva/plus-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';

// material
import { Card, CardContent, styled } from '@material-ui/core';
import axios from 'axios';
import { Link as RouterLink, useNavigate,useParams } from 'react-router-dom';

import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';

import Page from '../components/Page';


const UserDetails = () => {
  const navigate = useNavigate();

  const { handleSubmit } = useForm();

  const params = useParams();
  const [gallery, setgallery] = useState([])
  const [Level, setLevel] = useState('')
  const [subsciption, setsubsciption] = useState([])
  useEffect(() => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/getprofilebyadmin`;
    axios.post(Livechaturl, { user_id: params.userId },
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      })
      .then((response) => {
        const outlook = response
        setgallery(outlook.data.data);
        setLevel(outlook.data.data.Level)
      })
    // const profileurl = `https://app.soundchatradio.com:3000/api/v1/auth/subscriberdetailsbyadmin`;
    // axios.post(profileurl, { user_id: params.userId },
    //   {
    //     headers: {
    //       'x-access-token': localStorage.getItem('token'),
    //     }
    //   })
    //   .then((response) => {
    //     const outlook = response
    //     setsubsciption(outlook.data.data);
    //   })
  }, [params.userId])
  const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/';
  const formik = useFormik({
    initialValues: {
      post_content: "",
      post_title: "",
      post_excerpt: "",
      post_name: "",
      post_type: "",
      video_url: "",
    },

  });

  if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
    navigate('/login', { replace: true });
  }
  const CardMediaStyle = styled('div')({
    position: 'relative',

  });

  const CoverImgStyle = styled('img')({
    top: 0,
    width: '80',
    height: '80',
    objectFit: 'cover',
    display: 'inline-block'

  });

  return (
    <Page title="User Details | SoundChatRadio">

      <Container maxWidth="lg" minWidth="sm">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User Details
        </Typography>
        {(Level==='Freemium') ? <Button
                        variant="contained"
                        component={RouterLink}
                        to={`/dashboard/subscriber/addsubscriber/${params.userId}`}
                        startIcon={<Icon icon={plusFill} />}
                    >
                        Create Subscription
                        
          </Button> : <Button
                        variant="contained"
                        component={RouterLink}
                        to={`/dashboard/subscriber/details/${params.userId}`}
                        startIcon={<Icon icon={eyeFill} />}
                    >
                        Subscription Details
                        
          </Button>}
       
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>

              <Grid item xs={12} md={12} lg={12}>

                <Grid container spacing={3}>

                  <Grid item xs={12} md={8} lg={8}>
                    <Stack spacing={3}>
                      <Card>

                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            Personal Details
        </Typography>

                          <ul className="list-group-left">
                            {/* <li className="list-group-item list-group-item-primary">Profile Pic.</li> */}
                            <li className="list-group-item list-group-item-primary">User Name</li>
                            <li className="list-group-item list-group-item-primary">Email</li>
                            <li className="list-group-item list-group-item-primary">Full Name</li>
                            <li className="list-group-item list-group-item-primary">Mobile No.</li>
                            <li className="list-group-item list-group-item-primary">User Type</li>
                            <li className="list-group-item list-group-item-primary">Country</li>
                            <li className="list-group-item list-group-item-primary">Joined Date</li>
                            <li className="list-group-item list-group-item-primary">Plan Start Date</li>
                            <li className="list-group-item list-group-item-primary">Plan End Date</li>
                          </ul>
                          <ul className="list-group-right">
                            {/* <li><Avatar alt={gallery.username} src={baseurl + gallery.profilpic} /></li> */}
                            <li className="list-group-item list-group-item-primary">{(gallery.username) ? <>{gallery.username}</> : <>N/A</>}</li>
                            {/* <li className="list-group-item list-group-item-primary">{(gallery.fname)?<>{gallery.fname}</>:<>N/A</>}</li> */}
                            <li className="list-group-item list-group-item-primary">{(gallery.email) ? <>{gallery.email}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(gallery.fname) ? <>{gallery.fname} {gallery.lname}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(gallery.mobileno) ? <>{gallery.mobileno}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(gallery.Level) ? <>{gallery.Level}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(gallery.country) ? <>{gallery.country}</> : <>N/A</>}</li>
                            {/* <li className="list-group-item list-group-item-primary">{gallery.post_content}</li> */}
                            {/* <li className="list-group-item list-group-item-primary">{gallery.post_author}</li> */}
                            <li className="list-group-item list-group-item-primary">{(moment(gallery.join_date).format('MMMM Do YYYY')) ? <>{moment(gallery.join_date).format('MMMM Do YYYY')}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(moment(gallery.plan_start).format('MMMM Do YYYY'))?<>{moment(gallery.plan_start).format('MMMM Do YYYY')}</>:<>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(moment(gallery.plan_end).format('MMMM Do YYYY'))?<>{moment(gallery.plan_end).format('MMMM Do YYYY')}</>:<>N/A</>}</li>
                          </ul>
                        </CardContent>
                      </Card>

                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <Stack spacing={3}>
                      <Card style={{ height: '445px' }}>

                        <CardContent>
                        <CardMediaStyle
                                sx={{
                                  ...((gallery.profilepic || gallery.profilepic) && {
                                    // pt: 'calc(100% * 4 / 3)',
                                    '&:after': {
                                      top: 0,
                                      content: "''",
                                      width: '80',
                                      height: '80',
                                      // position: 'absolute',
                                      // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                                    }
                                  }),
                                  ...(gallery.profilepic && {
                                    // pt: {
                                    //   xs: 'calc(100% * 4 / 3)',
                                    //   sm: 'calc(100% * 3 / 4.66)'
                                    // }
                                  })
                                }}
                              >

                                <CoverImgStyle alt={gallery.profilepic} src={baseurl + gallery.profilepic} style={{ height: "216px" }}/>


                              </CardMediaStyle>
                        </CardContent>
                      </Card>
                    </Stack>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Form>
        </FormikProvider>


        <ToastContainer />

      </Container>
    </Page>
  );
};

export default UserDetails;