import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link as RouterLink, useNavigate,useLocation } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  styled,
  Switch
} from '@material-ui/core';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Spinner } from '../components/Spinner/index';


// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/optionmore';

//
import USERLIST from '../_mocks_/user';
// import { CenterFocusStrong } from '@material-ui/icons';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'username', label: 'User Name(full name)', alignRight: false },
  // { id: 'device_token', label: 'Device Token', alignRight: false },
  { id: 'Level', label: 'User Type', alignRight: false },
  { id: 'is_block', label: 'Block Status', alignRight: false },
  { id: 'blocked_date', label: 'Blocked Date', alignRight: false },
  // { id: 'Action', label: 'Action' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}



export default function AllUser() {
  const navigate = useNavigate();
  const {state} = useLocation();
  const { promiseInProgress } = usePromiseTracker();
  if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
    navigate('/login', { replace: true });
  }
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('email');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [userContent, setuserContent] = useState([]);

  useEffect(() => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/alluserlist`;
    const bodyFormData = new FormData();
    trackPromise(
      axios.post(Livechaturl, {},
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
          }
        })
        .then((response) => {
          const outlook = response
          if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
            navigate('/login', { replace: true });
            localStorage.setItem("token", "");
            localStorage.setItem("userid", '0');
            localStorage.setItem("email", '');
            toast.warning(outlook.data.message)
          }
          else {
            setuserContent(outlook.data.data);
          }
        })
    );
  }, [state]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userContent.map((n) => n.username);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userContent.length) : 0;

  const filteredUsers = applySortFilter(userContent, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/';
  const handleSwichChange = (event) => {
    const Livechaturl1 = `https://app.soundchatradio.com:3000/api/v1/auth/userstatus`;
    axios.post(Livechaturl1, {user_id:event.target.name}, {
        'Content-Type': 'multipart/form-data', headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    })
        .then((response) => {
            const outlook = response
            console.log(response);
        })
    const bodyFormData = new FormData();
        bodyFormData.append('user_id', event.target.name);
        if(event.target.checked===false){
        bodyFormData.append('is_block', "0");
        }
        else{
            bodyFormData.append('is_block', "1");
        }
              const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/updateprofilebytheadmin`;
              axios.post(Livechaturl, bodyFormData, {
                  'Content-Type': 'multipart/form-data', headers: {
                      'x-access-token': localStorage.getItem('token'),
                  }
              })
                  .then((response) => {
                      const outlook = response
                      if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                          navigate('/login', { replace: true });
                          localStorage.setItem("token", "");
                          localStorage.setItem("userid", '0');
                          localStorage.setItem("email", '');
                          toast.warning(outlook.data.message)
                      }
                      else {
                          navigate(`/dashboard/alluser`, { state: { id: event.target.name} });
                          if(outlook.data.data.is_block){
                            toast.success('User Blocked Successfully')
                          }
                          else{
                            toast.success('User Un-Blocked Successfully')
                          }
                          
                      }
                  })
  };

  return (
    <Page title=" Block-Unblock User | SoundChatRadio">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Block-Unblock User List
          </Typography>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            name="Search User By User Name"
          />
          {/* <Button
                        variant="contained"
                        component={RouterLink}
                        to="/dashboard/user/adduser"
                        startIcon={<Icon icon={plusFill} />}
                    >
                        Add New User
                        
          </Button> */}
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            Add New User
          </Button> */}
        </Stack>

        <Card>
          {
            (promiseInProgress === true) ?
              <Spinner />
              :
              <>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={userContent.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            const { id,is_block, username, email,device_token, fname, lname, mobileno, country, profilepic, updatedAt, action,Level,plan_end,createdAt,blocked_date } = row;
                            const isItemSelected = selected.indexOf(username) !== -1;

                            return (
                              <TableRow
                                hover
                                key={username}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  {/* <Checkbox
                        checked={isItemSelected}
                        onChange={(event) => handleClick(event, id)}
                      /> */}
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    <Avatar alt={username} src={baseurl+profilepic} />
                                    <Typography variant="subtitle2" noWrap>
                                      {username}
                                    </Typography>
                                  </Stack>
                                </TableCell>

                                {/* <TableCell align="left">{device_token}</TableCell> */}
                                <TableCell align="left">{Level}</TableCell>
                                <TableCell align="left"><Switch
                                                    checked= { is_block}
                                                    onChange={handleSwichChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    name={id}
                                                    /></TableCell>
                                <TableCell align="left">{moment(blocked_date).format('MMMM Do YYYY')}</TableCell>

                                {/* <TableCell align="center">
                                  <UserMoreMenu id={id} />
                                </TableCell> */}

                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={userContent.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>

          }
          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}


        </Card>
        <ToastContainer />

      </Container>
    </Page>
  );
}
