import { useState, useEffect } from 'react';
// material
import { Container, Stack, Typography, Paper, Link, Dialog, Button, Grid, Tooltip, FormControl, Chip, Popover } from '@material-ui/core';
import axios from 'axios';
import { Link as RouterLink, useNavigate, NavLink, useLocation, useParams } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import { useFormik, Form, FormikProvider } from 'formik';
import SendIcon from '@mui/icons-material/Send';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Image } from 'antd';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
// import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import msgOutline from '@iconify/icons-eva/message-square-outline';
import { Icon } from '@iconify/react';
import { withStyles } from '@material-ui/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ReactPlayer from 'react-player';
import CloseIcon from '@mui/icons-material/Close';
import playicon from '@iconify/icons-eva/play-circle-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';

// import { Button as cmdbtn, Comment, Form, Header } from 'semantic-ui-react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
import ReactPaginate from 'react-paginate';
import ShowMoreText from 'react-show-more-text';
import parse from 'html-react-parser'

// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "./swiperstyle.css";

// components
import Page from '../components/Page';
import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar
} from '../components/_dashboard/Timeline';
//
import PRODUCTS from '../_mocks_/products';

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  // height: 60,
  lineHeight: '60px',
  padding: '10px'
}));

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '0px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
export default function TimelineReply() {
  const navigate = useNavigate();

  if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
    navigate('/login', { replace: true });
  }

  const [openFilter, setOpenFilter] = useState(false);
  const { state } = useLocation();
  const params = useParams();
  const [totalCount, setTotalCount] = useState(0);
  const [Title, setTitle] = useState('');
  const [gallery, setgallery] = useState([])
  const [comments, setcomments] = useState([])
  const [likes, setlikes] = useState([])
  const [galleries, setgalleries] = useState([])


  useEffect(() => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/listtimelinereplyadmin`;
    axios.post(Livechaturl, { id_comment: params.commentId, page: currentPage, limit: 25 }, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
      }
    })
      .then((response) => {
        const outlook = response
        // setgallery(outlook.data.data);
        setcomments(outlook.data.data.reply.rows)
        setTotalCount(outlook.data.data.reply.count)
        setTitle(outlook.data.data.comments.comments)
        // setTotalCount(outlook.data.data.comments.count
        // setlikes(outlook.data.data.likes)
        // setgalleries(outlook.data.data.timelinemedia);
      })
  }, [params.commentId, state])
  // We start with an empty list of items.
  //  const items = [];
  //  for (let i=1; i <= totalCount; i+1) {
  //   items.push(i);
  // }
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + 25;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(totalCount / 25));
  }, [itemOffset, totalCount]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * 25) % totalCount;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
    setCurrentPage(event.selected + 1);
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/listtimelinereplyadmin`;
    axios.post(Livechaturl, { id_comment: params.commentId, page: event.selected + 1, limit: 25 }, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
      }
    })
      .then((response) => {
        const outlook = response
        // setgallery(outlook.data.data);
        setcomments(outlook.data.data.reply.rows)
        setTotalCount(outlook.data.data.reply.count)
        setTitle(outlook.data.data.comments.comments)
        // setTotalCount(outlook.data.data.comments.count)
        // setlikes(outlook.data.data.likes)
        // setgalleries(outlook.data.data.timelinemedia);
      })
  };
  const [expand, setExpand] = useState(false);
  const onClick = () => {
    setExpand(!expand);
  };
  const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/';

  const handleClickdelete = (id) => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/admindeletetimelinereply`;
    axios.post(Livechaturl, {
      'reply_id': id,
      'id_comment':params.commentId
    },
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      }).then((response) => {
        const outlook = response
        // alert(outlook.data.message);
        if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
          navigate('/login', { replace: true });
          localStorage.setItem("token", "");
          localStorage.setItem("userid", '0');
          localStorage.setItem("email", '');
          toast.warning(outlook.data.message)
        }
        else {
          navigate(`/dashboard/timeline/comment/reply/${params.timelineId}/${params.commentId}`, { state: { id: 'kk' } });
          toast.success(outlook.data.message)
        }
      })
  }
  return (
    <Page title="Timeline Media Reply List | SoundChatRadio">
      <Container>

        <Item key={6} elevation={6}>
          <Stack spacing={2} sx={{ pb: 1, pr: 3, pl: 1 }}>

            <Stack direction="row" alignItems="center" justifyContent="space-between">


              <Typography variant="h4" sx={{ mb: 0 }} >
              <ShowMoreText
        lines={1}
        more='view more'
        less='view less'
        onClick={onClick}
        expanded={expand}
        width={1000}
      >
        {parse(Title)}
      </ShowMoreText>
                {/* Replies- {totalCount} On {Title} */}
                </Typography>
            </Stack>
          </Stack>
        </Item>


        <List sx={{ width: '100%', bgcolor: 'background.paper', padding: 1, margin: 1 }}>
        <Button
                        variant="contained"
                        component={RouterLink}
                        to={`/dashboard/timeline/details/${params.timelineId}`}
                        startIcon='<'
                    >
                        Back
                        
          </Button>
          <Grid container spacing={2} style={{ padding: '30px' }}>
            {comments && comments.map((cmts, index) => (
              <>
                <Grid item xs={10} sm={10} md={10} >
                  <Item><ListItem alignItems="flex-start">
                    <ListItemAvatar>
                    {(cmts.admin != null) ? <><Avatar alt="sound chat admin" src="/static/soundpic.png" /></> : <><Avatar alt={cmts.user.fname + cmts.user.lname} src={baseurl+cmts.user.profilepic} /></>
                            }
                      
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {(cmts.admin != null) ? <>Sound Chat Radio Admin</> : <>{cmts.user.fname}</>
                            }
                          </Typography>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="h1"
                            variant="body1"
                            color="text.secondary"
                          >
                            &nbsp;&nbsp;{moment.utc(cmts.createdAt).format('llll')}
                          </Typography>

                        </>

                      }
                      secondary={
                        <>
                        <Typography
                            sx={{ display: 'inline',fontSize: '0.8rem' }}
                            component="p"
                            variant="body1"
                            color="text.secondary"
                          >
                          <ShowMoreText
        lines={2}
        more='view more'
        less='view less'
        onClick={onClick}
        expanded={expand}
        width={700}
      >
        {parse(cmts.reply)}
      </ShowMoreText></Typography>
                        </>

                      }
                      style={{backgroundColor:'#f2f1f1',padding:'10px',borderRadius:'25px'}}
                    />
                    <h6 style={{
                                zIndex: 1, position: 'absolute', right: 28,
                                top: 0
                              }}>
                                <IconButton onClick={() => {
                                    handleClickdelete(cmts.id);
                                  // handleClickdelete();
                                   
                                }}>
                                  <Icon icon={trash2Outline} width={20} height={20} color='red' style={{}} />
                                </IconButton>

                              </h6>
                  </ListItem></Item>
                </Grid>

                <Grid item xs={2} sm={2} md={2}>
                   <Item/>
                </Grid>
              </>
            ))}


          </Grid>
          {(comments && comments.length>0)?<>
          <div className="paginationnew">
            <ReactPaginate
              previousLabel="prev"
              nextLabel="next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active" />
          </div>
          </>:
<></>}
        </List>
        <ToastContainer />
      </Container>
    </Page>
  );
}
