import PropTypes from 'prop-types';
// material
import { Grid,Switch } from '@material-ui/core';
import {NavLink,useNavigate} from 'react-router-dom';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShopProductCard from './ProductCard';
// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired
};

export default function ProductList({ products, ...other }) {
  const navigate = useNavigate();

  const handleSwichChange = (event) => {

    const bodyFormData = new FormData();
        bodyFormData.append('id', event.target.name);
        if(event.target.checked===false){
        bodyFormData.append('status', "0");
        }
        else{
            bodyFormData.append('status', "1");
        }

        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/updateproduct`;
            axios.post(Livechaturl, bodyFormData, { 'Content-Type': 'multipart/form-data',headers: {
                'x-access-token': localStorage.getItem('token'),
              } })
                .then((response) => {
                    const outlook = response
                    // window.location.href = '/dashboard/products'
                    if((outlook.data.status===400) || (outlook.data.status===401) || (outlook.data.status===403)){
                      navigate('/login', { replace: true });
                      localStorage.setItem("token", "");
                      localStorage.setItem("userid", '0');
                      localStorage.setItem("email", '');
                      toast.warning(outlook.data.message)
                  }
                  else{
                    navigate('/dashboard/products', { state: { id: event.target.name} });
                    toast.success("status updated")
                  }
                })
  };
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        
        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <h6 style={{zIndex: 1,position: 'absolute'}}><Switch
                                                    checked={product.status}
                                                    onChange={handleSwichChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    name={product.id}
                                                    /></h6>
          <NavLink to ={`/dashboard/products/details/${product.id}`}>
          <ShopProductCard product={product} />
          </NavLink>
        </Grid>
      ))}
    </Grid>
  );
}
