import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useRef, useState, React } from 'react';

// material
import { Box, Card, Link, Typography, Stack, Button, Dialog } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import trash2Outline from '@iconify/icons-eva/play-circle-fill';
import { Icon } from '@iconify/react';
import { withStyles } from '@material-ui/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ReactPlayer from 'react-player';
import CloseIcon from '@mui/icons-material/Close';


// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});


// ----------------------------------------------------------------------

// ShopProductCard.propTypes = {
//   product: PropTypes.object
// };

export default function ShopProductCard({ product }) {
  const Imageurl = "https://app.soundchatradio.com/soundradiobackend/images/thumbnail/"
  const Imageurl1 = "https://app.soundchatradio.com/soundradiobackend/images/timeline/"
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deleterow = () => {
    handleClose()
  };
  // const { name, cover, price, colors, status, priceSale } = product;

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>

        {(product.media_thumbnail && product.media_type==='video') ? <><Link onClick={() => {
          handleClickOpen();
        }}><Icon icon={trash2Outline} width={50} height={50} color='#c4c6c7' style={{
          position: 'absolute',
          top: '41%',
          left: '0',
          right: '0',
          zIndex: '999',
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          margin: '0 auto'
        }} /></Link> <ProductImgStyle alt={product} src={Imageurl + product.media_thumbnail} /> </> : <ProductImgStyle alt={product} src={Imageurl1 + product.media_name} />}
      </Box>

      {/* <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <ColorPreview colors={colors} />
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through'
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography>
            &nbsp;
            {fCurrency(price)}
          </Typography>
        </Stack>
      </Stack> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle >
          {/* <Button onClick={handleClose}>NO</Button> */}
          <IconButton onClick={deleterow} autoFocus color="error" sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
          </IconButton>
          
        </DialogTitle>
        <DialogContent >
        <div className='player-wrapper'>
                            <ReactPlayer
                              url={Imageurl1 + product.media_name}
                              className='react-player'
                              playing='false'
                              controls='true'
                              auto='false'
                              width="500"

                            />
                          </div>
        </DialogContent>
        
      </Dialog>
    </Card>

  );
}
