import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonPinCircleSharpIcon from '@mui/icons-material/PersonPinCircleSharp';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import "./order-details.css"

// material
import {
  Stack, Grid, Container, Typography,
  InputLabel, MenuItem, FormControl, Select, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Avatar
} from '@mui/material';

// material
import { Card, CardContent, styled } from '@material-ui/core';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Page from '../components/Page';

const OrderDetails = () => {
  const navigate = useNavigate();
  if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
    navigate('/login', { replace: true });
  }
  const params = useParams();
  const [content_type, setcontent_type] = useState('');
  const [order_trasaction_ref, setorder_trasaction_ref] = useState('');
  const [delivery_fee, setdelivery_fee] = useState('');
  // console.log(content_type)


  const handleChangeforselect = (event) => {
    setcontent_type(event.target.value);
  };

  const [orderData, setOrderData] = useState({

    user: "",
    full_name: "",
    invoice_file_name: "",
    createdAt: "",
    shipping_phone_number: "",
    shipping_address: "",
    postcode: "",
    city: "",
    country: "",
    payment_mode: "",
    trasaction_status: "",
    user_id: ""
  })
  const [totalQty, setTotalQty] = useState("")
  const [totalAmount, setTotalAmount] = useState("")
  const [productorderitems, setProductorderitems] = useState([]);
  const [infostate, setinfoStatus] = useState(false);

  // console.log(productorderitems)

  useEffect(() => {
    const profileurl = `https://app.soundchatradio.com:3000/api/v1/auth/productorderdetailsbyadmin`;
    axios.post(profileurl, { id: params.orderId }, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
      }
    },)
      .then((response) => {
        const outlook = response

        setOrderData({
          id: response.data.data.id,
          refrenceId: response.data.data.order_trasaction_ref,
          transactionId: response.data.data.trasaction_id,
          user: response.data.data.user,
          full_name: response.data.data.full_name,
          invoice_file_name: response.data.data.invoice_file_name,
          createdAt: response.data.data.createdAt,
          "shipping_phone_number": response.data.data.shipping_phone_number,
          shipping_address: response.data.data.shipping_address,
          postcode: response.data.data.postcode,
          city: response.data.data.city,
          country: response.data.data.country,
          payment_mode: response.data.data.payment_mode,
          trasaction_status: response.data.data.trasaction_status,
          user_id: response.data.data.user_id

        });

        // console.log(response.data.data.user_id)
        setProductorderitems(response.data.data.productorderitems);
        setTotalQty(response.data.data.total_quantity);
        setTotalAmount(response.data.data.total_amount)

        setcontent_type(outlook.data.data.tracking_status)
        setorder_trasaction_ref(response.data.data.order_trasaction_ref)
        setdelivery_fee(response.data.data.delivery_fee)
        // console.log(outlook.data.data.tracking_status)
        // window.location.href = '/dashboard/gallery';

      })
  }, [params.orderId, infostate])
  const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/product/';
  const LoginSchema = Yup.object().shape({

  });

  const formik = useFormik({
    initialValues: {
      post_content: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/updateproductorderbyadmin`;
      axios.post(Livechaturl, { order_id: params.orderId, tracking_status: content_type, user_id: orderData.user_id }, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      },)

        .then((response) => {
          const outlook = response
          if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
            navigate('/login', { replace: true });
            localStorage.setItem("token", "");
            localStorage.setItem("userid", '0');
            localStorage.setItem("email", '');
            toast.warning(outlook.data.message)
          }
          else {
            // window.location.href = `/dashboard/productorder/details/${params.orderId}`;
            // navigate(`/dashboard/productorder/details/${params.orderId}`, { replace: true });
            toast.success(outlook.data.message)
            setinfoStatus(!infostate)
          }
        })
    }
  });

  const { isSubmitting, handleSubmit } = formik;

  const CardMediaStyle = styled('div')({
    position: 'relative',

  });

  const CoverImgStyle = styled('img')({
    top: 0,
    width: '80',
    height: '80',
    objectFit: 'cover',
    display: 'inline-block'

  });



  return (
    <Page title="Order details | SoundChatRadio">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Order Details
          </Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <div className="order-details-main-head">
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card className="order-details">
                      <CardContent>
                        <Grid container>
                          <Grid item md={6} sm={6} xs={6}>
                            <img style={{ "width": "121px" }} src="/static/soundpic.png" className="detail-logo" alt="err" />
                          </Grid>
                          <Grid item md={6} sm={6} xs={6} pb={5} >
                            <div className="order-same-detaul" style={{"textAlign": "end", }}>
                              <Typography variant="subtitle1" className="text-muted">
                                Order Date: <span> {moment(orderData.createdAt).format("MMM D, YYYY")}
                                </span>

                              </Typography>
                              <Typography variant="h6">
                                Order Id: <span>  {`#SOUNDCHAT${orderData.id}`}</span>

                              </Typography>
                              {/* <Typography variant="h6">
                                Order Ref. Id: <span>  {`#SOUNDCHAT${orderData.refrenceId}`}</span>
                                
                              </Typography>
                              <Typography variant="h6">
                                Order Tran. Id: <span>  {`#SOUNDCHAT${orderData.transactionId}`}</span>
                                
                              </Typography> */}
                                 <a href={`https://app.soundchatradio.com/soundradiobackend/images/pdf/${orderData.invoice_file_name}`} target='_blank' rel="noreferrer" >
                                                            <Button color='success' size="large" variant="contained" style={{marginTop:"12px"}} > 
                                                            Invoice   <CloudDownloadIcon style={{ "marginLeft": "13px", "marginTop": "-2px" }} />
                                                             </Button>
                                                         </a>
                            </div>
                          </Grid>
                        </Grid>

                        {/* Transaction and Order Status */}
                        <Grid container>
                          <Grid item md={6} sm={6} xs={6}>
                            <div className="order-currency" style={{ "marginTop": "22px" }}>
                              <Typography variant="h6">Transaction Status</Typography>
                              <Typography variant="body1" className='text-muted-span' style={{ "color": "green" }}>{orderData.trasaction_status === "succeeded" ? (
                                <div>Success</div>
                              ) : null}</Typography>
                            </div>
                          </Grid>

                          <Grid container item xs={12} sm={6} md={6} lg={6} my={2} >
                          <Grid item   md={2} lg={2} />
                              <Grid item xs={6} sm={8} md={6} lg={6}>
                                <Stack spacing={2} style={{textAlign:"center"}}>
                                  <FormControl>
                                    <InputLabel id="demo-simple-select-helper-label">Order Status </InputLabel>
                                    <Select 
                                     style={{ "fontSize": "0.8rem"}}
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      value={content_type}
                                      label="Status"
                                      onChange={handleChangeforselect}
                                    >
                                      <MenuItem value="ORDER_CONFIRM">ORDER_CONFIRM</MenuItem>
                                      <MenuItem value="ORDER_CANCEL">ORDER_CANCEL</MenuItem>
                                      <MenuItem value="ORDER_PENDING">ORDER_PENDING</MenuItem>
                                      <MenuItem value="ORDER_DISPATCHED">ORDER_DISPATCHED</MenuItem>
                                      <MenuItem value="ORDER_READY">ORDER_READY</MenuItem>
                                      <MenuItem value="ORDER_ON_WAY">ORDER_ON_WAY</MenuItem>
                                      <MenuItem value="ORDER_DELIVERED">ORDER_DELIVERED</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Stack>
                              </Grid>
                            
                              <Grid item xs={6} sm={4} md={4} lg={4} style={{textAlign:"end"}}>
                                <Stack spacing={3}>
                                  <div>
                                    <Button style={{width:"76%"}}
                                      color='info'
                                      size="large"
                                      type="submit"
                                      variant="contained"
                                      loading={isSubmitting.toString()}>
                                      Update
                                    </Button>
                                  </div>
                                </Stack>
                              </Grid>
                          
                        
                          </Grid>
                        </Grid>

                        <hr className="mt-2 mb-0" />

                        {/* Shipping Information */}
                        <Grid container spacing={2} pb={4} pt={4}>
                          <Grid item md={5} sm={4} xs={12}>
                            <div className="payment-methode">
                              <Typography variant="h3" className="main-text-oder-details">SHIPPING INFORMATION</Typography>

                              <span className="text-muted">Name: </span>  <span className='text-muted-span'>{orderData.full_name} </span><br />
                              <span className="text-muted">Phone Number: </span> <span className='text-muted-span'> {orderData.shipping_phone_number}</span> <br />
                              <span className="text-muted">Address: </span>  <span className='text-muted-span'>{orderData.shipping_address}</span><br />

                            </div>
                          </Grid>

                          {/* Payment Method */}
                          <Grid item md={4} sm={4} xs={12}>
                            <div className=" new">
                              <Typography variant="h3" className="main-text-oder-details">PAYMENT METHOD</Typography>
                              {/* Payment details */}
                              <p className="mb-1">
                
                                <b> <span className="text-muted">Payment Type: </span>{" "}</b>
                                <span className='text-muted-span'> {orderData.payment_mode}</span>
                              </p>
                            </div>
                          </Grid>

                          {/* Order Summary */}
                          <Grid item md={3} sm={4}>
                            <div className="payment-methode new-1">
                              <Typography variant="h3" className="main-text-oder-details">ORDER SUMMARY</Typography>

                              <span className="text-muted">Shipping Charge: </span>
                              <span className='text-muted-span'>  $ {parseFloat(delivery_fee).toFixed(2)}</span> <br />
                              {/* <span className="text-muted">Sub - Total: </span> $32,432 <br /> */}
                              <span className="text-muted">Total Amount: </span>
                              <span className='text-muted-span'> $ {parseFloat(totalAmount).toFixed(2)} </span> <br />
                              {/* <span className="text-muted">Grand Total: </span> $234,234 <br /> */}

                            </div>
                          </Grid>
                        </Grid>

                        <hr className="mt-2 mb-0" />

                        {/* Table */}
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12}  lg={12}>
                            <div className="table-responsive">
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Unit Price</TableCell>
                                    <TableCell>Qty.</TableCell>
                                    <TableCell>Size</TableCell>
                                    <TableCell>Total Amount</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {productorderitems.map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell>
                                        <div className="order-img-new">
                                          <img src={`${baseurl}${item.product.image}`} alt="{item.product.title}" />
                                        </div>
                                      </TableCell>
                                      <TableCell>
                                        <div className="order-new-site-1">
                                          <Typography variant="h6" className="order-bttn text-muted-span">{item.product.title}</Typography>
                                          {/* <Typography variant="body2" className='text-muted-span'>Official Rumble Talk T-Shirt, powered by SoundChat Radio.</Typography> */}
                                        </div>
                                      </TableCell>
                                      <TableCell className='text-muted-span' >$ {parseFloat(item.product.Price).toFixed(2)}</TableCell>
                                      <TableCell className='text-muted-span' >   {item.product_quantity}          </TableCell>
                                      <TableCell className='text-muted-span' > {item.product_size? item.product_size: "Not Avaialble"}</TableCell>
                                      <TableCell className='text-muted-span' >$ {parseFloat(parseFloat(item.product_quantity) * parseFloat(item.product.Price)).toFixed(2)}      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
            </div>


          </Form>
        </FormikProvider>

        <ToastContainer />
      </Container>
    </Page>
  );
};

export default OrderDetails;