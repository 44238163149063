import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        //                  We don't want to let default form submission happen here,
        //                  which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            //                  Stripe.js hasn't yet loaded.
            //                  Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //                  `Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "https://app.soundchatradio.com/dashboard/productorder/payment-status/",
            },
        });
// https://app.soundchatradio.com/dashboard/productorder/payment-status/

        if (result.error) {
            //                   Show error to your customer (for example, payment details incomplete)
            console.log(result.error, "error");
        } else {
            console.log(result, "success")

            //                     Your customer will be redirected to your `return_url`. For some payment
            //                     methods like iDEAL, your customer will be redirected to an intermediate
            //                     site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <div className="payment-paid">
            <form onSubmit={handleSubmit} className='payment-form'>
                <PaymentElement />
                <button className='pay-b' disabled={!stripe}>Pay Now</button>
            </form>
        </div>
    )
};

export default CheckoutForm;