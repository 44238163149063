import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import "./Spinner.css";

export const Spinnerloading = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div className="spinner">
        <p style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>Content uploading on the server</p>
        <Loader type="Oval" color="#4fa94d"
          wrapperStyle={{}}
          wrapperClass=""
          visible={Boolean(1)}
          ariaLabel='oval-loading'
          secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2} height="100" width="100" />
        {/* <Circles
  height="80"
  width="80"
  color="#4fa94d"
  ariaLabel="circles-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={Boolean(1)}
/> */}
      </div>
    )
  );
};
