// material
import { Box, Grid, Container, Typography } from '@material-ui/core';
import { Link as RouterLink, useNavigate, NavLink, useLocation, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Spinner } from '../components/Spinner/index';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const navigate = useNavigate();
  const { promiseInProgress } = usePromiseTracker();

  if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
    navigate('/login', { replace: true });
  }
  const [premiumContent, setPremiumContent] = useState([]);
  const [freeContent, setfreeContent] = useState([]);

  useEffect(() => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/orderreport`;
    const bodyFormData = new FormData();
    trackPromise(
      axios.post(Livechaturl, {},
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
          }
        })
        .then((response) => {
          const outlook = response
          if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
            navigate('/login', { replace: true });
            localStorage.setItem("token", "");
            localStorage.setItem("userid", '0');
            localStorage.setItem("email", '');
            toast.warning(outlook.data.message)
          }
          else {
            setPremiumContent(outlook.data);
            setfreeContent(outlook.data);
          }
        })
    )
  }, []);
  return (
    <Page title="Dashboard | SoundChatRadio">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          {
            (promiseInProgress === true) ?
              <Spinner />
              :
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <AppWeeklySales props={freeContent} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <AppNewUsers props={freeContent} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <AppItemOrders props={freeContent} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <AppBugReports props={freeContent} />
                </Grid>

                <Grid item xs={12} md={6} lg={8}>
                  <AppWebsiteVisits props={freeContent} />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <AppCurrentVisits props={freeContent}/>
                </Grid>

                {/* <Grid item xs={12} md={6} lg={8}>
                  <AppConversionRates />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <AppCurrentSubject />
                </Grid> */}

                {/* <Grid item xs={12} md={6} lg={8}>
                  <AppNewsUpdate />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <AppOrderTimeline />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <AppTrafficBySite />
                </Grid>

                <Grid item xs={12} md={6} lg={8}>
                  <AppTasks />
                </Grid> */}
              </>
          }
        </Grid>
      </Container>
    </Page>
  );
}
