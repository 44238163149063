import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink,useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { trackPromise,usePromiseTracker } from 'react-promise-tracker';
import { Spinner } from '../components/Spinner/index';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, InterviewMoreMenu } from '../components/_dashboard/optionmore';
//
import USERLIST from '../_mocks_/user';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'feature_img', label: 'Poster', alignRight: false },
    { id: 'tvposter', label: 'TV Poster', alignRight: false },
    // { id: 'poster desktop', label: ' Desktop Poster', alignRight: false },
    { id: 'post_title', label: 'Title', alignRight: false },
    // { id: 'content', label: 'Description', alignRight: false },
    { id: 'interview_type', label: 'Type', alignRight: false },
    { id: 'video_url', label: 'Video Url', alignRight: false },
    // { id: 'createdat', label: 'Created At', alignRight: false },
    { id: 'updatedAt', label: 'Updated At', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'Action', label: 'Action' } 
    
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.post_title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            {/* <CloseIcon /> */}
            Close
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

export default function Interview() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const { promiseInProgress } = usePromiseTracker();

    if ((localStorage.getItem('token')==='' && localStorage.getItem("userid")==='0') || (localStorage.getItem('token')===null && localStorage.getItem("userid")===null)) {
        navigate('/login', { replace: true });
    }
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('post_title');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [premiumContent, setPremiumContent] = useState([]);
    const [freeContent, setfreeContent] = useState([]);
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/interviewss`;
        const bodyFormData = new FormData();
        trackPromise(
        axios.post(Livechaturl, {},
            {
              headers: {
                'x-access-token': localStorage.getItem('token'),
              }
            })
            .then((response) => {
                const outlook = response
                if((outlook.data.status===400) || (outlook.data.status===401) || (outlook.data.status===403)){
                    navigate('/login', { replace: true });
                    localStorage.setItem("token", "");
                    localStorage.setItem("userid", '0');
                    localStorage.setItem("email", '');
                    toast.warning(outlook.data.message)
                }
                else{
                setPremiumContent(outlook.data.data);
                }
            })
        )
    }, [state]);
    const finalArray = [...premiumContent];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = finalArray.map((n) => n.post_title);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    }; 

    const handleSwichChange = (event) => {

        const bodyFormData = new FormData();
            bodyFormData.append('interview_id', event.target.name);
            if(event.target.checked===false){
            bodyFormData.append('status', "0");
            }
            else{
                bodyFormData.append('status', "1");
            }

            const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/interviewsupdate`;
            axios.post(Livechaturl, bodyFormData, { 'Content-Type': 'multipart/form-data',headers: {
                'x-access-token': localStorage.getItem('token'),
              } })
                .then((response) => {
                    const outlook = response
                    // navigate('/dashboard/interview', { replace: true });
                    // window.location.href = '/dashboard/interview';
                    // toast.success(outlook.data.message)
                    if((outlook.data.status===400) || (outlook.data.status===401) || (outlook.data.status===403)){
                        navigate('/login', { replace: true });
                        localStorage.setItem("token", "");
                        localStorage.setItem("userid", '0');
                        localStorage.setItem("email", '');
                        toast.warning(outlook.data.message)
                    }
                    else{
                    navigate('/dashboard/interview/interviewlist', { state: { id: event.target.name} });
                  toast.success("status updated")
                    }
                })
      };


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - finalArray.length) : 0;

    const filteredUsers = applySortFilter(finalArray, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/interviews/';
    return (
        <Page title="Interview | SoundChatRadio">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Interviews List
          </Typography>
          <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                        name="Search Interview By Title"
                    />
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/dashboard/interview/addinterview"
                        startIcon={<Icon icon={plusFill} />}
                    >
                        Add New Interview
                        
          </Button>
                </Stack>

                <Card>
                    {/* <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    /> */}
 {
        (promiseInProgress === true) ?
        <Spinner/>
      :
      <>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={finalArray.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            const { 
                                                createdAt,
                                                feature_img,
                                                feature_img_mobile,
                                                id,
                                                post_content,
                                                interview_type,
                                                post_title,
                                                updatedAt,
                                                video_url,
                                                tvposter,
                                            status } = row;
                                            const isItemSelected = selected.indexOf(post_title) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={post_title}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        {/* <Checkbox
                                                            checked={isItemSelected}
                                                            onChange={(event) => handleClick(event, id)}
                                                        /> */}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Avatar variant="square" alt={post_title} src={baseurl+feature_img} />
                                                        </Stack>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Avatar variant="square" alt={post_title} src={baseurl+tvposter} />
                                                        </Stack>
                                                        </TableCell>
                                                        {/* <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Avatar variant="square" alt={post_title} src={feature_img_mobile} />
                                                        </Stack>
                                                    </TableCell> */}
                                                    <TableCell align="left">{post_title}</TableCell>
                                                    {/* <TableCell align="left">{post_content}</TableCell> */}
                                                    <TableCell align="left">{interview_type}</TableCell>
                                                    <TableCell align="left">{video_url}</TableCell>
                                                    
                                                    {/* <TableCell align="left">{moment(createdAt).format('MMMM Do YYYY')}</TableCell> */}
                                                    <TableCell align="left">{moment(updatedAt).format(' Do MMMM YYYY')}</TableCell>
                                                    <TableCell align="left"><Switch
                                                    checked= { status}
                                                    onChange={handleSwichChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    name={id}
                                                    /></TableCell>
                                                    <TableCell align="center">
                                                        <InterviewMoreMenu id={id}/>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={finalArray.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </>
}
                </Card>
            
      <ToastContainer />
            </Container>
        </Page>
    );
}
