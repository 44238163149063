import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Spinner } from '../components/Spinner/index';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu, ShowMoreMenu } from '../components/_dashboard/optionmore';
//
import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------
const TABLE_HEAD = [

  { id: 'post_title', label: 'Day', alignRight: false },
  { id: 'post_name ', label: 'Show Name', alignRight: false },
  { id: 'content_type', label: 'Type', alignRight: false },
  { id: 'scheduleperdays', label: 'No. Of Shows', alignRight: false },
  { id: 'feature_img', label: 'Poster', alignRight: false },
  { id: 'updatedAt', label: 'Updated Date', alignRight: false },
  { id: 'Action', label: 'Action' }

];


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.post_title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Interview() {
  const navigate = useNavigate();
  const { promiseInProgress } = usePromiseTracker();

  if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
    navigate('/login', { replace: true });
  }
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('post_title');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [freeContent, setfreeContent] = useState([]);

  useEffect(() => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/adminlistshowschedule`;
    const bodyFormData = new FormData();
    trackPromise(
      axios.post(Livechaturl, {},
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
          }
        })
        .then((response) => {
          const outlook = response
          if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
            navigate('/login', { replace: true });
            localStorage.setItem("token", "");
            localStorage.setItem("userid", '0');
            localStorage.setItem("email", '');
            toast.warning(outlook.data.message)
          }
          else {
            setfreeContent(outlook.data.data);
          }
        })
    )
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = freeContent.map((n) => n.post_title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - freeContent.length) : 0;

  const filteredUsers = applySortFilter(freeContent, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/podcast/';

  return (
    <Page title="Shows | SoundChatRadio">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Shows
          </Typography>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            name="Search Show By Day"
          />
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>

        <Card>
          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}
          {
            (promiseInProgress === true) ?
              <Spinner />
              :
              <>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={freeContent.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            const {
                              content_type,
                              createdAt,
                              feature_img,
                              id,
                              post_author,
                              post_content,
                              post_excerpt,
                              post_name,
                              post_title,
                              post_type,
                              scheduleperdays,
                              updatedAt
                            } = row;
                            const isItemSelected = selected.indexOf(post_title) !== -1;

                            return (
                              <TableRow
                                hover
                                key={post_title}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            /> */}
                                </TableCell>
                                {/* <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={avatarUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell> */}
                                <TableCell align="left">{post_title}</TableCell>
                                <TableCell align="left">{post_name}</TableCell>
                                <TableCell align="left">{(content_type === 'free_content') ? 'FREE' : 'PAID'}</TableCell>
                                <TableCell align="left">{scheduleperdays.length}</TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    <Avatar variant="square" alt={post_title} src={feature_img} />
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{moment(updatedAt).format(' Do MMMM YYYY')}</TableCell>

                                <TableCell align="right">
                                  <ShowMoreMenu id={id} />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={freeContent.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
          }
        </Card>
        <ToastContainer />
      </Container>
    </Page>
  );
}
