import { Icon } from '@iconify/react';
import { useRef, useState, React } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate, NavLink as RouterNavLink, useLocation, useParams } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';

// material
import { Menu, MenuItem, ListItemIcon, ListItemText, Button, Dialog, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import CloseIcon from '@material-ui/icons/Close';
// ----------------------------------------------------------------------

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});



export default function TimelineMoreMenu(props) {
  const navigate = useNavigate();
  const [premiumContent, setPremiumContent] = useState([]);
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deleterow = () => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/deletetimelinepost`;
    axios.post(Livechaturl, {
      id: props.id,
    },
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      }).then((response) => {
        const outlook = response
        // alert(outlook.data.message);
        if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
          navigate('/login', { replace: true });
          localStorage.setItem("token", "");
          localStorage.setItem("userid", '0');
          localStorage.setItem("email", '');
          toast.warning(outlook.data.message)
        }
        else {
          navigate('/dashboard/timeline', { state: { id: 'kk' } });
          //  window.location.href = '/dashboard/gallery';
          // navigate('/dashboard/gallery', { replace: true });
          toast.success(outlook.data.message)
        }
      })
    handleClose()
  }
  const editrow = () => {
    // alert(JSON.stringify(props.id))
  }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem component={RouterNavLink} to={`/dashboard/timeline/updatetimeline/${props.id}`} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} />
          </ListItemIcon>
        </MenuItem>
        <MenuItem component={RouterNavLink} to={`/dashboard/timeline/details/${props.id}`} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={eyeFill} width={24} height={24} />
          </ListItemIcon>
        </MenuItem>
        <br />
        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>

            <Icon icon={trash2Outline} width={24} height={24} onClick={handleClickOpen}  />
          </ListItemIcon>
        </MenuItem>


      </Menu>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          Do You Really Want To Delete This Record ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>NO</Button>
          <Button onClick={deleterow} autoFocus color="error">
            YES
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
}
