import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TextField,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    Grid, InputAdornment
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { LoadingButton } from '@material-ui/lab';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, AdminMoreMenu } from '../components/_dashboard/optionmore';
//
import USERLIST from '../_mocks_/user';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'username', label: 'User Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'updatedAt', label: 'Updated Date', alignRight: false },
    { id: 'Action', label: 'Action' }
]
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


export default function AdminSetting() {
    const { state } = useLocation();
    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('First name required'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('password is required')
    });

    const formik = useFormik({
        initialValues: {
            username: '',
            email: '',
            password: ''
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            axios.post("https://app.soundchatradio.com:3000/api/v1/auth/createadmin", {
                username: values.username,
                email: values.email,
                password: values.password
            },
                {
                    headers: {
                        'x-access-token': localStorage.getItem('token'),
                    }
                })
                .then((response) => {
                    const outlook = response
                    if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                        navigate('/login', { replace: true });
                        localStorage.setItem("token", "");
                        localStorage.setItem("userid", '0');
                        localStorage.setItem("email", '');
                        toast.warning(outlook.data.message)
                    }
                    else {
                        navigate('/dashboard/adminlist', { state: { id: "kk" } });
                        toast.success(outlook.data.message)
                    }
                })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const navigate = useNavigate();

    if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
        navigate('/login', { replace: true });
    }
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('email');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [premiumContent, setPremiumContent] = useState([]);
    const [freeContent, setfreeContent] = useState([]);

    useEffect(() => {
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/listadmin`;
        const bodyFormData = new FormData();
        axios.post(Livechaturl, {},
            {
                headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            })
            .then((response) => {
                const outlook = response
                if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                    navigate('/login', { replace: true });
                    localStorage.setItem("token", "");
                    localStorage.setItem("userid", '0');
                    localStorage.setItem("email", '');
                    toast.warning(outlook.data.message)
                }
                else {
                    setPremiumContent(outlook.data.data);
                }
                // setfreeContent(outlook.data.data);
            })
    }, [state]);
    // const finalArray = [...premiumContent, ...freeContent];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = premiumContent.map((n) => n.email);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };




    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - premiumContent.length) : 0;

    const filteredUsers = applySortFilter(premiumContent, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;



    return (
        <Page title="Admin List | SoundChatRadio">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Admin List
          </Typography>
                </Stack>

                <Card>
                    {/* <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    /> */}

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={premiumContent.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {premiumContent.map((row) => {
                                        const {
                                            username,
                                            email,
                                            updatedAt, id } = row;
                                        const isItemSelected = selected.indexOf(username) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={username}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    {/* <Checkbox
                                                            checked={isItemSelected}
                                                            onChange={(event) => handleClick(event, order_id)}
                                                        /> */}
                                                </TableCell>
                                                <TableCell align="left">{username}</TableCell>

                                                <TableCell align="left">{email}</TableCell>

                                                <TableCell align="left">{moment(updatedAt).format('MMMM Do YYYY')}</TableCell>
                                                {/* <TableCell align="left" ><button onClick={deleterow} name={id} style={{ cursor: 'pointer', padding: '3px' }}>DELETE</button></TableCell> */}
                                                <TableCell align="right">
                                                    <AdminMoreMenu id={id} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0} >
                        <Typography variant="h6" gutterBottom ml={5} mt={5}>
                            Create New Admin
          </Typography>
                    </Stack>
                    <Stack spacing={3}>


                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid key='k' item xs={12} sm={12} md={4} ml={4} mb={4}>
                                        <TextField
                                            fullWidth
                                            autoComplete="username"
                                            type="text"
                                            label="username"
                                            {...getFieldProps('username')}
                                            error={Boolean(touched.username && errors.username)}
                                            helperText={touched.username && errors.username}
                                        />
                                    </Grid>

                                    <Grid key='k' item xs={12} sm={12} md={4} ml={4} mb={4}>
                                        <TextField
                                            fullWidth
                                            autoComplete="email"
                                            type="text"
                                            label="email"
                                            {...getFieldProps('email')}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                        />
                                    </Grid>

                                    <Grid key='k' item xs={12} sm={12} md={4} ml={4} mb={4}>
                                        <TextField
                                            fullWidth
                                            autoComplete="current-password"
                                            type={showPassword ? 'text' : 'password'}
                                            label="Password"
                                            {...getFieldProps('password')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            error={Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password}
                                        />
                                    </Grid>

                                    <Grid key='k' item xs={12} sm={3} md={3} mb={4}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            loading={isSubmitting}
                                        >
                                            Create
        </Button>
                                    </Grid>

                                </Grid>
                            </Form>
                        </FormikProvider>

                    </Stack>
                </Card>

                <ToastContainer />
            </Container>
        </Page>
    );
}
