import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { Link as RouterLink, useNavigate, NavLink, useLocation, useParams } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
// material
import {
    Link,
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel, Box, Grid, Container, Typography,
    InputLabel, MenuItem, FormControl, Select, Button
} from '@mui/material';

// material
import { Card, CardHeader, CardContent } from '@material-ui/core';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
        },
    },
    inputimage: {
        position: 'relative',
        left: '43px',
        padding: '0px'
    }
}));

export default function UpdatePPVevents() {
    const location = useLocation();
    const params = useParams();
    const [content_type, setcontent_type] = useState('free_content');
    const [interview_type, setinterview_type] = useState('YELLOW_TENT');
    const [start_date, setstart_date] = useState(null);
    const [end_date, setend_date] = useState(null);
    const [gallery, setgallery] = useState([]);
    const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/';
    const navigate = useNavigate();

    if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
        navigate('/login', { replace: true });
    }
    const classes = useStyles();
    const [gallery_pics, setgallery_pics] = useState();
    const [mobileimage, setMobileimage] = useState();
    const [desktopimage, setDesktopimage] = useState();
    const [title, settitle] = useState('')
    const [image_url, setimage_url] = useState('')
    // const { handleSubmit, control } = useForm();

    const handleChange = ({ fileList }) => {
        setMobileimage(fileList[0].originFileObj)
    }

    const handleChange1 = ({ fileList }) => {
        setDesktopimage(fileList[0].originFileObj)
    }
    const handleChangeforselect = (event) => {
        setcontent_type(event.target.value);
    };
    const handleChangeforselect2 = (event) => {
        setinterview_type(event.target.value);
    };
    const LoginSchema = Yup.object().shape({
        title: Yup.string('title is required'),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            image_url: ''
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            const bodyFormData = new FormData();
            bodyFormData.append('id', params.ppveventsId);
            bodyFormData.append('mobileimage', mobileimage);
            bodyFormData.append('image_url', image_url);
            bodyFormData.append('title', title);
            bodyFormData.append('startdate', moment(start_date).format('YYYY-MM-DD HH:mm:ss'));
            bodyFormData.append('enddate', moment(end_date).format('YYYY-MM-DD HH:mm:ss'));
            const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/updateppvevent`;
            axios.post(Livechaturl, bodyFormData, {
                'Content-Type': 'multipart/form-data', headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            }).then((response) => {
                const outlook = response
                if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                    navigate('/login', { replace: true });
                    localStorage.setItem("token", "");
                    localStorage.setItem("userid", '0');
                    localStorage.setItem("email", '');
                    toast.warning(outlook.data.message)
                }
                else {
                    navigate('/dashboard/ppvevents', { replace: true });
                    toast.success(outlook.data.message)
                }
            })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
    const onChange = ({ fileList }) => {
        setgallery_pics({ fileList });
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    const onAudioPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Audio(src);
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    useEffect(() => {
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/ppveventdetails`;
        axios.post(Livechaturl, { id: params.ppveventsId }, { 'Content-Type': 'multipart/form-data' })
            .then((response) => {
                const outlook = response
                setgallery(outlook.data.data);
                settitle(outlook.data.data.title)
                setimage_url(outlook.data.data.image_url)
                setstart_date(outlook.data.data.startdate);
                setend_date(outlook.data.data.enddate)
                // window.location.href = '/dashboard/gallery';
            })
    }, [params.ppveventsId])
    const changetitle = (event) => {
        settitle(event.target.value)
    }
    const changeimage_url = (event) => {
        setimage_url(event.target.value)
    }
    return (
        <Page title="Update ppvevents | SoundChatRadio">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Update PPV & Event
                    </Typography>
                </Stack>

                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={12} lg={12}>
                                <Card>
                                    <CardHeader title="Update Details" />
                                    <CardContent>
                                        <Grid container spacing={3}>

                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="title"
                                                            type="text"
                                                            label="Title"
                                                            {...getFieldProps('title')}
                                                            value={title}
                                                            onChange={changetitle}
                                                            error={Boolean(touched.title && errors.title)}
                                                            helperText={touched.title && errors.title}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="image_url"
                                                            type="text"
                                                            label="Enter Link"
                                                            {...getFieldProps('image_url')}
                                                            value={image_url}
                                                            onChange={changeimage_url}
                                                            error={Boolean(touched.image_url && errors.image_url)}
                                                            helperText={touched.image_url && errors.image_url}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        {/* <InputLabel shrink htmlFor="circle">
                                                            Start Date</InputLabel> */}
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Event Start Date"
                                                                value={start_date}
                                                                onChange={(newValue) => {
                                                                    setstart_date(newValue);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        {/* <InputLabel shrink htmlFor="circle">
                                                            End Date</InputLabel> */}
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Event End Date"
                                                                value={end_date}
                                                                onChange={(newValue) => {
                                                                    setend_date(newValue);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sx={{ mt: 3 }}>
                                <Card>
                                    <CardHeader title="Image For PPV & Event" />
                                    <Grid container spacing={3}>
                                        <Grid xs={12} md={3} lg={3} sx={{
                                            mt: 3, ml
                                                : 3, mb: 0, pb: 0
                                        }}>
                                            <Stack spacing={3}>
                                                <CardContent>
                                                    <img src={baseurl + gallery.img} alt={gallery.img} width='250' height='250' />
                                                </CardContent>
                                            </Stack>
                                        </Grid>


                                        <Grid xs={12} md={6} lg={6} sx={{ mt: 3 }}>
                                            <Stack spacing={3}>
                                                <CardContent>
                                                    <ImgCrop >
                                                        <Upload
                                                            action="https://app.soundchatradio.com"
                                                            className="upload-img"
                                                            listType="picture-card"
                                                            onChange={handleChange}
                                                            onPreview={onPreview}
                                                            maxCount={1}

                                                        >
                                                            + Upload New Image
                            </Upload>
                                                    </ImgCrop>
                                                </CardContent>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                </Card>
                            </Grid>
                        </Grid>
                        <Stack spacing={3} sx={{ mt: 4 }} >
                            <div>
                                <Button

                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Update
                                </Button>
                            </div>
                        </Stack>
                    </Form>
                </FormikProvider>
                <ToastContainer />
            </Container>
        </Page>
    );
}
