import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TextField,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    Grid, InputAdornment
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { LoadingButton } from '@material-ui/lab';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, CouponMoreMenu } from '../components/_dashboard/optionmore';
//
import USERLIST from '../_mocks_/user';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'User Name', alignRight: false },
    { id: 'coupon_code', label: 'Coupon Code', alignRight: false },
    { id: 'amount_off', label: 'Amount Off', alignRight: false },
    { id: 'percent_off', label: '% Off', alignRight: false },
    { id: 'updatedAt', label: 'Updated Date', alignRight: false },
    { id: 'Action', label: 'Action' }
]
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


export default function CouponList() {
    const { state } = useLocation();
    const params = useParams();

    const navigate = useNavigate();

    if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
        navigate('/login', { replace: true });
    }
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [premiumContent, setPremiumContent] = useState([]);
    const [title_name, settitle_name] = useState('');

    useEffect(() => {
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/plandetails`;
        const bodyFormData = new FormData();
        axios.post(Livechaturl, {id:params.planId},
            {
                headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            })
            .then((response) => {
                const outlook = response
                if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                    navigate('/login', { replace: true });
                    localStorage.setItem("token", "");
                    localStorage.setItem("userid", '0');
                    localStorage.setItem("email", '');
                    toast.warning(outlook.data.message)
                }
                else {
                    setPremiumContent(outlook.data.data.coupons);
                    settitle_name(outlook.data.data.plan_type)
                }
                // setfreeContent(outlook.data.data);
            })
    }, [state]);
    // const finalArray = [...premiumContent, ...freeContent];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = premiumContent.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };




    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - premiumContent.length) : 0;

    const filteredUsers = applySortFilter(premiumContent, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;



    return (
        <Page title="Coupon List | SoundChatRadio">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Coupon List For {title_name} 
          </Typography>
                </Stack>

                <Card>
                    {/* <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    /> */}

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={premiumContent.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {premiumContent.map((row) => {
                                        const {
                                            name,
                                            coupon_code,
                                            percent_off,
                                            amount_off,
                                            updatedAt, id } = row;
                                        const isItemSelected = selected.indexOf(name) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={name}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    {/* <Checkbox
                                                            checked={isItemSelected}
                                                            onChange={(event) => handleClick(event, order_id)}
                                                        /> */}
                                                </TableCell>
                                                <TableCell align="left">{name}</TableCell>

                                                <TableCell align="left">{coupon_code}</TableCell>
                                                <TableCell align="left">{amount_off}</TableCell>
                                                <TableCell align="left">{percent_off}</TableCell>
                                                <TableCell align="left">{moment(updatedAt).format('MMMM Do YYYY')}</TableCell>
                                                {/* <TableCell align="left" ><button onClick={deleterow} name={id} style={{ cursor: 'pointer', padding: '3px' }}>DELETE</button></TableCell> */}
                                                <TableCell align="right">
                                                    <CouponMoreMenu id={id} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>

                <ToastContainer />
            </Container>
        </Page>
    );
}
