import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useFormik, Form, FormikProvider } from 'formik';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// material
import {
  Stack, Grid, Container, Typography } from '@mui/material';

// material
import { Card, CardContent, styled } from '@material-ui/core';
import axios from 'axios';
import {  useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import ReactPlayer from 'react-player';

import Page from '../components/Page';


const InterviewDetails = () => {
  const navigate = useNavigate();
  if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
    navigate('/login', { replace: true });
  }
  const { handleSubmit } = useForm();
 
  const params = useParams();
  const [gallery, setgallery] = useState([])
  useEffect(() => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/PhoneinterviewsviewDetail`;
    axios.post(Livechaturl, { phoneinterviews_id: params.interviewId }, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        const outlook = response
        setgallery(outlook.data.data);
        // window.location.href = '/dashboard/gallery';
      })
  }, [params.interviewId])
  const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/interviews/';
  const formik = useFormik({
    initialValues: {
      post_content: "",
      post_title: "",
      post_excerpt: "",
      post_name: "",
      post_type: "",
      video_url: "",
    },

  });
  const CardMediaStyle = styled('div')({
    position: 'relative',

  });

  const CoverImgStyle = styled('img')({
    top: 0,
    width: '80',
    height: '80',
    objectFit: 'cover',
    display: 'inline-block'

  });



  return (
    <Page title=" Phone Interview Details | SoundChatRadio">
      <Container maxWidth="lg" minWidth="sm">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Phone Interview Details
        </Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>

              <Grid item xs={12} md={12} lg={12}>

                <Grid container spacing={3}>

                  <Grid item xs={12} md={7} lg={7}>
                    <Stack spacing={3}>
                      <Card>

                        <CardContent>
                          <ul className="list-group-left">
                            <li className="list-group-item list-group-item-primary">Name</li>
                            {/* <li className="list-group-item list-group-item-primary">Description</li> */}
                            <li className="list-group-item list-group-item-primary">Video URL</li>
                            <li className="list-group-item list-group-item-primary">Content Type</li>
                            <li className="list-group-item list-group-item-primary">Created Date</li>
                            <li className="list-group-item list-group-item-primary">Updated Date</li>
                          </ul>
                          <ul className="list-group-right">
                            <li className="list-group-item list-group-item-primary">{(gallery.post_title)?<>{gallery.post_title}</>:<>N/A</>}</li>
                            {/* <li className="list-group-item list-group-item-primary">{(gallery.post_content)?<>{gallery.post_content}</>:<>N/A</>}</li> */}
                            <li className="list-group-item list-group-item-primary">{(gallery.video_url)?<>{gallery.video_url}</>:<>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(gallery.content_type)?<>{gallery.content_type}</>:<>N/A</>}</li>
                            {/* <li className="list-group-item list-group-item-primary">{gallery.post_content}</li> */}
                            {/* <li className="list-group-item list-group-item-primary">{gallery.post_author}</li> */}
                            <li className="list-group-item list-group-item-primary">{(moment(gallery.createdAt).format('MMMM Do YYYY'))?<>{moment(gallery.createdAt).format('MMMM Do YYYY')}</>:<>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(moment(gallery.updatedAt).format('MMMM Do YYYY'))?<>{moment(gallery.updatedAt).format('MMMM Do YYYY')}</>:<>N/A</>}</li>
                          </ul>
                        </CardContent>
                      </Card>

                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={5} lg={5}>
                    <Stack spacing={3}>
                      <Card>
                        <CardContent >
                          <Grid container spacing={3}>

                            <Grid item xs={12} md={12} lg={12}>
                              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Poster Pic.
                            </Typography>
                              <CardMediaStyle
                                sx={{
                                  ...((gallery.feature_img || gallery.feature_img) && {
                                    // pt: 'calc(100% * 4 / 3)',
                                    '&:after': {
                                      top: 0,
                                      content: "''",
                                      width: '80',
                                      height: '80',
                                      // position: 'absolute',
                                      // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                                    }
                                  }),
                                  ...(gallery.feature_img && {
                                    // pt: {
                                    //   xs: 'calc(100% * 4 / 3)',
                                    //   sm: 'calc(100% * 3 / 4.66)'
                                    // }
                                  })
                                }}
                              >

                                <CoverImgStyle alt={gallery.feature_img} src={baseurl + gallery.feature_img} style={{ height: "179px" }} />


                              </CardMediaStyle>
                            </Grid>

                          </Grid>
                        </CardContent>
                      </Card>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mt: 3 }}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <Stack spacing={3}>
                      <Card>
                        <CardContent>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Interview Video
                  </Typography>
                          <div className='player-wrapper'>
                            <ReactPlayer
                              url={gallery.video_url}
                              className='react-player'
                              playing='false'
                              controls='true'
                              auto='false'
                              width="500"

                            />
                          </div>
                        </CardContent>
                      </Card>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mt: 3 }}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <Stack spacing={3}>
                      <Card>
                        <CardContent>
                          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                            Video Description
                  </Typography>
                          <span>{(gallery.post_content)?<>{gallery.post_content}</>:<>N/A</>}</span>
                        </CardContent>
                      </Card>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>


        <ToastContainer />
      </Container>
    </Page>
  );
};

export default InterviewDetails;