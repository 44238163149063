import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import AllUser from './pages/AllUser';
import UpdateUser from './pages/UpdateUser';
import AddUser from './pages/AddUser'
import Subscriber from './pages/Subscriber';
import UpdateSubscriber from './pages/UpdateSubscriber';
import AddSubscriber from './pages/AddSubscriber'
import UserDetails from './pages/UserDetails';
import SubscriberDetails from './pages/SubscriberDetails';
import NotFound from './pages/Page404';
import Interview from './pages/Interview';
import InterviewDetails from './pages/Interviewdetails';
import AddInterview from './pages/AddInterview'
import UpdateInterview from './pages/UpdateInterview'
import Gallery from './pages/Gallery';
import GalleryImages from './pages/GalleryImages';
import Radiopodcast from './pages/Radiopodcast';
import Musicalbum from './pages/Musicalbum';
import AlbumList from './pages/AlbumList';

import Shows from './pages/Shows';
import Events from './pages/Events';
import Banners from './pages/Banners';
import Homeslider from './pages/Homeslider';
import PPVevents from './pages/PPVevents';
import AddPPVevents from './pages/AddPPVevents';
import UpdatePPVevents from './pages/UpdatePPVevents';
import AddGallery from './pages/AddGallery';
import AddMusicalbum from './pages/AddMusic';
import UpdateGallery from './pages/UpdateGallery';

import UpdateMusicAlbum from './pages/UpdateMusicAlbum'

import AddBanner from './pages/AddBanner';
import UpdateBanner from './pages/UpdateBanner';
import AddHomeslider from './pages/AddHomeslider';
import UpdateHomeslider from './pages/UpdateHomeslider';
import Order from './pages/Order';
import UpdateOrder from './pages/UpdateOrder'
import OrderDetails from './pages/OrderDetails'
import Report from './pages/Report';
import AddProduct from './pages/AddProduct';
import AddProductAvaibility from './pages/AddProductAvaibility';
import ReportDetails from './pages/ReportDetails';
import ProductDetails from './pages/ProductDetails'
import UpdateProduct from './pages/UpdateProduct'
import UpdateProductAvaibility from './pages/UpdateProductAvaibility'
import UpdateProductImage from './pages/UpdateProductImage'
import AddProductImage from './pages/AddProductImage';
import PhoneInterview from './pages/PhoneInterview';
import PhoneInterviewDetails from './pages/PhoneInterviewdetails';
import AddPhoneInterview from './pages/AddPhoneInterview'
import UpdatePhoneInterview from './pages/UpdatePhoneInterview'
import Showsperday from './pages/Showsperday';
import Updateshowschedule from './pages/UpdateShowSchedule';
import Addshowschedule from './pages/AddShowSchedule';
import Updateshow from './pages/UpdateShow';
import UpdateLiveContent from './pages/UpdateLiveContent';
import LiveContentDetails from './pages/LiveContentDetails';
import AdsQuery from './pages/AdsQuery';
import AdsQueryDetails from './pages/AdsQueryDetails';
import Setting from './pages/Setting';

import Recaptv from './pages/Recaptv';
import RecaptvDetails from './pages/Recaptvdetails';
import AddRecaptv from './pages/AddRecaptv';
import UpdateRecaptv from './pages/UpdateRecaptv';
import RecaptvSetting from './pages/RecaptvSetting';

import Notification from './pages/Notification';
import AddNotification from './pages/AddNotification';
import AdminSetting from './pages/AdminSetting';
import UpdateAdmin from './pages/UpdateAdmin';
import ReacptvVideoFrame from './pages/ReacptvVideoFrame';
import ChatUi from './pages/ChatUi';
import ChatIcon from './pages/ChatIcon';
import Plan from './pages/Plan';
import AddCoupon from './pages/AddCoupon';
import CouponList from './pages/CouponList';
import Timeline from './pages/Timeline';
import TimelineImages from './pages/TimelineImages';
import AddTimeline from './pages/AddTimeline';
import UpdateTimeline from './pages/UpdateTimeline';
// import Posts from './pages/Posts';
import TimelineReply from './pages/TimelineReply';
import AddOrder from './pages/AddOrder';
import PaymentStatus from './pages/PaymentStatus';
import HomeGifBanners from './pages/HomeGifBanners';
import AddHomeGifBanner from './pages/AddHomeGifBanner';
import UpdateHomeGifBanner from './pages/UpdateHomeGifBanner';

// ----------------------------------------------------------------------

export default function Router() {
  const admin_type = localStorage.getItem('type'); 
  const Isloggin = localStorage.getItem('token');
  return useRoutes([
    {
      path: '/dashboard',
      // element: <DashboardLayout />,
      element: Isloggin ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: '/dashboard/app', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'subscriber', element: <Subscriber /> },
        { path: 'subscriber/updatesubscriber/:subscriberId', element: <UpdateSubscriber /> },
        { path: 'subscriber/addsubscriber/:userId', element: <AddSubscriber /> },
        { path: 'subscriber/details/:userId', element: <SubscriberDetails /> },
        { path: 'user/details/:userId', element: <UserDetails /> },
        { path: 'user/updateuser/:userId', element: <UpdateUser /> },
        { path: 'user/adduser', element: <AddUser /> },
        { path: 'banner/homeimagebanner/addbanner', element: <AddBanner /> },
        { path: 'banner/homeimagebanner/updatebanner/:bannerId', element: <UpdateBanner /> },
        { path: 'interview/interviewlist', element: <Interview /> },
        { path: 'interview/interviewlist/details/:interviewId', element: <InterviewDetails /> },
        { path: 'interview/addinterview', element: <AddInterview /> },
        { path: 'interview/interviewlist/updateinterview/:interviewId', element: <UpdateInterview /> },
        { path: 'interview/setting', element: <Setting /> },
        { path: 'adminlist', element: (admin_type==='admin')?<NotFound/>:<AdminSetting /> },
        { path: 'adminlist/updateadmin/:adminId', element: (admin_type==='admin')?<NotFound/>:<UpdateAdmin /> },
        { path: 'gallery/addgallery', element: <AddGallery /> },
        { path: 'gallery/updategallery/:galleryId', element: <UpdateGallery /> },
        { path: 'gallery', element: <Gallery /> },
        { path: 'gallery/details/:galleryId', element: <GalleryImages /> },

        { path: 'timeline/addtimeline', element: <AddTimeline /> },
        { path: 'timeline/updatetimeline/:galleryId', element: <UpdateTimeline /> },
        { path: 'timeline', element: <Timeline /> },
        { path: 'timeline/details/:galleryId', element: <TimelineImages /> },
        { path: 'timeline/comment/reply/:timelineId/:commentId', element: <TimelineReply /> },

        // { path: 'posts', element: <Posts /> },


        { path: 'radio-podcast', element: <Radiopodcast /> },
        { path: 'music-album', element: <Musicalbum /> },

        { path: 'music-album/addmusicalbum', element: <AddMusicalbum /> },
        { path: 'music-album/details/:albumId', element: <AlbumList /> },
        { path: 'music-album/updatemusicalbum/:albumId', element: <UpdateMusicAlbum /> },
        { path: 'music-album/details/:albumId', element: <AlbumList /> },
        { path: 'shows', element: <Shows /> },
        { path: 'shows/showslist/:dayId', element: <Showsperday /> },
        { path: 'shows/showslist/addshowschedule/:dayId', element: <Addshowschedule /> },
        { path: 'shows/showslist/updateshowschedule/:dayId/:showId', element: <Updateshowschedule /> },
        { path: 'shows/updateshow/:dayId', element: <Updateshow /> },
        { path: 'livecontent', element: <Events /> },
        { path: 'banner/homeimagebanner', element: <Banners /> },
        { path: 'homeslider', element: <Homeslider /> },
        { path: 'ppvevents', element: <PPVevents /> },
        { path: 'ppvevents/addppvevents', element: <AddPPVevents /> },
        { path: 'ppvevents/updateppvevents/:ppveventsId', element: <UpdatePPVevents /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'productorder', element: <Order /> },
        { path: 'productorder/details/:orderId', element: <OrderDetails /> },
        { path: 'productorder/update/:orderId', element: <UpdateOrder /> },
        // by adarsh
        { path: 'productorder/addorder', element: <AddOrder /> },  
        { path: 'productorder/payment-status', element:<PaymentStatus/>  },  
   

        //
     
        { path: 'query', element: <Report /> },
        { path: 'query/details/:reportId', element: <ReportDetails /> },
        { path: 'adsquery', element: <AdsQuery /> },
        { path: 'adsquery/details/:queryId', element: <AdsQueryDetails /> },
        { path: 'products/addproduct', element: <AddProduct /> },
        { path: 'products/details/addproductavaibility/:productId', element: <AddProductAvaibility /> },
        { path: 'products/details/updateproductavaibility/:productId/:itemId', element: <UpdateProductAvaibility /> },
        { path: 'products/details/addproductimage/:productId', element: <AddProductImage /> },
        { path: 'products/details/updateproductimage/:productId/:itemId', element: <UpdateProductImage /> },
        { path: 'products/details/:productId', element: <ProductDetails /> },
        { path: 'products/update/:productId', element: <UpdateProduct /> },
        { path: 'homeslider/addhomeslider', element: <AddHomeslider /> },
        { path: 'homeslider/updatehomeslider/:sliderId', element: <UpdateHomeslider /> },
        { path: 'phoneinterview', element: <PhoneInterview /> },
        { path: 'phoneinterview/details/:interviewId', element: <PhoneInterviewDetails /> },
        { path: 'phoneinterview/addinterview', element: <AddPhoneInterview /> },
        { path: 'phoneinterview/updateinterview/:interviewId', element: <UpdatePhoneInterview /> },
        { path: 'livecontent/details/:livecontentId', element: <LiveContentDetails /> },
        { path: 'livecontent/updatelivecontent/:livecontentId', element: <UpdateLiveContent /> },
        { path: 'setting', element: <Setting /> },

        { path: 'recaptv/recaptvlist', element: <Recaptv /> },
        { path: 'recaptv/recaptvlist/details/:recaptvId', element: <RecaptvDetails /> },
        { path: 'recaptv/addrecaptv', element: <AddRecaptv /> },
        { path: 'recaptv/recaptvlist/updaterecaptv/:recaptvId', element: <UpdateRecaptv /> },
        { path: 'recaptv/setting', element: <RecaptvSetting /> },
        { path: 'notification/notificationlist', element: <Notification /> },
        { path: 'notification/addnotification', element: <AddNotification /> },
        { path: 'chaticon/chaticonlist', element: <ChatIcon /> },
        { path: 'plan', element: <Plan /> },
        { path: 'plan/addcoupon/:planId', element: <AddCoupon /> },
        { path: 'plan/couponlist/:planId', element: <CouponList /> },
        { path: 'alluser', element: <AllUser /> },
        { path: 'banner/homegifbanner', element: <HomeGifBanners /> },
        { path: 'banner/homegifbanner/addbanner', element: <AddHomeGifBanner /> },
        { path: 'banner/homegifbanner/updatebanner/:bannerId', element: <UpdateHomeGifBanner /> },
        
      ]
    },
    {
      path: '/',
      // element: <LogoOnlyLayout />,
      element: !Isloggin ? <LogoOnlyLayout /> : <Navigate to="/dashboard" />,
      children: [
        { path: '/', element: <Navigate to="/login" replace /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        // { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> },
        // { path: 'show/interview/:interviewId', element: <InterviewVideoFrame /> },
        { path: 'show/recaptv/:recaptvId', element: <ReacptvVideoFrame /> },
        // { path: 'chatroom', element: <ChatUi /> },
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
