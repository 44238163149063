import { useState,useEffect } from 'react';

import PropTypes from 'prop-types';
// material
import { Grid, Button, Dialog } from '@material-ui/core';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link as RouterLink, useNavigate, NavLink as RouterNavLink, useLocation, useParams } from 'react-router-dom';

import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

// ProductList.propTypes = {
//   products: PropTypes.array.isRequired
// };

export default function ProductList({ products, ...other }) {
  const navigate = useNavigate();
  const params = useParams();
  const [productss,setproductss]=useState([])
  const [gallery_id,setgallery_id]=useState('')
  const [image_id,setimage_id]=useState('')
  useEffect(()=> {
    setproductss(products)
    setgallery_id(params.galleryId)
}, [products,params.galleryId])

const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deleterow = () => {

    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/deletegalleryimage`;
    axios.post(Livechaturl, {
      id: image_id,
    },
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      }).then((response) => {
        const outlook = response
        // alert(outlook.data.message);
        if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
          navigate('/login', { replace: true });
          localStorage.setItem("token", "");
          localStorage.setItem("userid", '0');
          localStorage.setItem("email", '');
          toast.warning(outlook.data.message)
        }
        else {
          navigate(`/dashboard/gallery/details/${gallery_id}`, { state: { id: 'kk' } });
          //  window.location.href = '/dashboard/gallery';
          // navigate('/dashboard/gallery', { replace: true });
          setimage_id('')
          toast.success(outlook.data.message)
        }
      })
    handleClose()
  }
  return (
    <>
    <Grid container spacing={3} {...other}>
      {productss.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <h6 style={{zIndex: 1,position: 'absolute'}}>
                         <IconButton  onClick={() => {
          handleClickOpen();
          setimage_id(product.id);
        }}>
        <Icon icon={trash2Outline} width={30} height={30} color='red' style={{backgroundColor:'black'}}/>
      </IconButton>
                        
          </h6>
          <ShopProductCard product={product} /> 
        </Grid>
      ))}
    </Grid>
    <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent>
      Do You Really Want To Delete This Record ?
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>NO</Button>
      <Button onClick={deleterow} autoFocus color="error">
        YES
      </Button>
    </DialogActions>
  </Dialog>
  <ToastContainer />
  </>
  );
}
