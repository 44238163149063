import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TextField,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    Grid,CardContent,CardHeader
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { LoadingButton } from '@material-ui/lab';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, OrderMoreMenu } from '../components/_dashboard/optionmore';
//
import USERLIST from '../_mocks_/user';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Icon', alignRight: false },
    { id: 'updatedAt', label: 'Updated Date', alignRight: false },
    { id: 'Action', label: 'Action' }
]
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    {/* <CloseIcon /> */}
            Close
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function ChatIcon() {
    const { state } = useLocation();
    const [mobileimage, setMobileimage] = useState();
    const LoginSchema = Yup.object().shape({
        
    });

    const formik = useFormik({
        initialValues: {
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            if (mobileimage) {
                const bodyFormData = new FormData();
                bodyFormData.append('mobileimage', mobileimage);
                axios.post("https://app.soundchatradio.com:3000/api/v1/auth/addChatIcon", bodyFormData, {
                    'Content-Type': 'multipart/form-data', headers: {
                        'x-access-token': localStorage.getItem('token'),
                    }
                })
                    .then((response) => {
                        const outlook = response
                        if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                            navigate('/login', { replace: true });
                            localStorage.setItem("token", "");
                            localStorage.setItem("userid", '0');
                            localStorage.setItem("email", '');
                            toast.warning(outlook.data.message)
                        }
                        else {
                            navigate('/dashboard/chaticon/chaticonlist', { state: { id: "kk" } });
                            toast.success(outlook.data.message)
                        }
                    })
            }
            else {
                toast.warning('please upload image with extension jpeg/png/gif.')
            }
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const navigate = useNavigate();

    if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
        navigate('/login', { replace: true });
    }
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('email');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [premiumContent, setPremiumContent] = useState([]);
    const [freeContent, setfreeContent] = useState([]);

    useEffect(() => {
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/chaticon`;
        const bodyFormData = new FormData();
        axios.post(Livechaturl, {})
            .then((response) => {
                const outlook = response
                if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                    navigate('/login', { replace: true });
                    localStorage.setItem("token", "");
                    localStorage.setItem("userid", '0');
                    localStorage.setItem("email", '');
                    toast.warning(outlook.data.message)
                }
                else {
                    setPremiumContent(outlook.data.data);
                }
                // setfreeContent(outlook.data.data);
            })
    }, [state]);
    // const finalArray = [...premiumContent, ...freeContent];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = premiumContent.map((n) => n.email);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - premiumContent.length) : 0;

    const filteredUsers = applySortFilter(premiumContent, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/emoticon/';

    const deleterow = (event) => {
        axios.post("https://app.soundchatradio.com:3000/api/v1/auth/deleteChatIcon", {
            id: event.target.name
        },
            {
                headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            })
            .then((response) => {
                const outlook = response
                if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                    navigate('/login', { replace: true });
                    localStorage.setItem("token", "");
                    localStorage.setItem("userid", '0');
                    localStorage.setItem("email", '');
                    toast.warning(outlook.data.message)
                }
                else {
                    navigate('/dashboard/chaticon/chaticonlist', { state: { id: event.target.name } });
                    toast.success(outlook.data.message)
                }
            })
    }

    const handleChange = ({ fileList }) => {
        setMobileimage(fileList[0].originFileObj)
    }


    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    return (
        <Page title="Chat Icon List Page | SoundChatRadio">
            <Container>
                

                <Card>
                    <Stack spacing={3}>


                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                
                            <Card>
                                <CardHeader title="Add Icon For Chat" />
                                <Grid container spacing={3}>
                                <Grid item xs={12} md={8} lg={8} sx={{ mt: 3 }}>
                                <CardContent>


                                    <Stack spacing={4}>

                                            <Upload
                                                action="https://app.soundchatradio.com"
                                                className="upload-img"
                                                listType="picture-card"
                                                onChange={handleChange}
                                                onPreview={onPreview}
                                                maxCount={1}
                                            >
                                                + Upload Icon For Chat
                                            </Upload>
                                    </Stack>
                                </CardContent>
                                </Grid>
                                <Grid item xs={12} md={3} lg={3} sx={{ mt: 3 }}>
                                <CardContent>
                                    <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            loading={isSubmitting}
                                            sx={{mt:25}}
                                        >
                                            Add
        </Button>
                                </CardContent>
                                </Grid>
                                </Grid>
                            </Card>
                        
                            </Form>
                        </FormikProvider>

                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={6} ml={6}>
                    <Typography variant="h4" gutterBottom>
                        Chat Icon List
          </Typography>
                </Stack>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={premiumContent.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {premiumContent.map((row) => {
                                        const {
                                            image_name,
                                            id,
                                            updatedAt } = row;
                                        const isItemSelected = selected.indexOf(image_name) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={image_name}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    {/* <Checkbox
                                                            checked={isItemSelected}
                                                            onChange={(event) => handleClick(event, order_id)}
                                                        /> */}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Avatar variant="square" alt={image_name} src={baseurl + image_name} style={{ height: '60px', width: '60px' }} />
                                                    </Stack>
                                                </TableCell>



                                                <TableCell align="left">{moment(updatedAt).format('MMMM Do YYYY')}</TableCell>
                                                <TableCell align="left" ><Button onClick={deleterow} name={id} autoFocus color="error">
                                                    DELETE
          </Button>

                                                </TableCell>

                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>

                <ToastContainer />
            </Container>
        </Page>
    );
}
