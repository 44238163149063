import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { Link as RouterLink, useNavigate, NavLink, useLocation, useParams } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import TextField from '@material-ui/core/TextField';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';

import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
// import Select from "react-select";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
// import FormControl from '@mui/material/FormControl';


// material
import {
    Link,
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel, Box, Grid, Container, Typography,
    InputLabel, MenuItem, FormControl, Select, Button
} from '@mui/material';

// material
import { Card, CardHeader, CardContent, Paper } from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
        },
    },
    inputimage: {
        position: 'relative',
        left: '43px',
        padding: '0px'
    }
}));

export default function UpdateUser(props) {
    const location = useLocation();
    const params = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [gallery, setgallery] = useState([])
    const [fname, setfname] = useState('')
    const [mobileno, setmobileno] = useState('')
    const [lname, setlname] = useState('')
    const [Level, setLevel] = useState('')
    const [Level_old, setLevel_old] = useState('')
    const [email, setemail] = useState('');
    const [country, setcountry] = useState('');
    const [poster, setposter] = useState('');
    const [cotegory, setCotegory] = useState([]);
    const [start_date, setstart_date] = useState(null);
    const [end_date, setend_date] = useState(null);
    const [start_date_old, setstart_date_old] = useState(null);
    const [end_date_old, setend_date_old] = useState(null);
    const [password, setpassword] = useState('');




    const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/';

    const navigate = useNavigate();
    const LoginSchema = Yup.object().shape({
        mobileno: Yup.string('mobile no is required'),
        fname: Yup.string('first name is required'),
        lname: Yup.string('last name is required'),
        email: Yup.string('email is required'),
        country: Yup.string('country is required')
    });

    const formik = useFormik({
        initialValues: {
            mobileno: '',
            fname: '',
            lname: '',
            email: '',
            country: '',
            password:''
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            const bodyFormData = new FormData();
            bodyFormData.append('user_id', params.userId);
            bodyFormData.append('profilepic', mobileimage);
            // bodyFormData.append('desktopimage', desktopimage);
            bodyFormData.append('mobileno', mobileno);
            bodyFormData.append('fname', fname);
            bodyFormData.append('lname', lname);
            bodyFormData.append('Level', Level);
            bodyFormData.append('email', email);
            bodyFormData.append('country', country);
            bodyFormData.append('password', values.password);
            const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/updateprofilebytheadmin`;
            axios.post(Livechaturl, bodyFormData, {
                'Content-Type': 'multipart/form-data', headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            })
                .then((response) => {
                    const outlook = response
                    if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                        navigate('/login', { replace: true });
                        localStorage.setItem("token", "");
                        localStorage.setItem("userid", '0');
                        localStorage.setItem("email", '');
                        toast.warning(outlook.data.message)
                    }
                    else {
                        navigate(`/dashboard/user/updateuser/${params.userId}`, { state: 'kk' });
                        toast.success(outlook.data.message)
                    }
                })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
        navigate('/login', { replace: true });
    }
    const classes = useStyles();
    const [gallery_pics, setgallery_pics] = useState();
    const [mobileimage, setMobileimage] = useState('');
    const [desktopimage, setDesktopimage] = useState('');
    // const { handleSubmit, control } = useForm();

    const handleChange = ({ fileList }) => {
        setMobileimage(fileList[0].originFileObj)
    }

    const handleChange1 = ({ fileList }) => {
        setDesktopimage(fileList[0].originFileObj)
    }

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    const onAudioPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Audio(src);
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    useEffect(() => {
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/getprofilebyadmin`;
        axios.post(Livechaturl, { user_id: params.userId },
            {
                headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            }).then((response) => {
                const outlook = response
                setgallery(outlook.data.data);
                setfname(outlook.data.data.fname);
                setmobileno(outlook.data.data.mobileno);
                setlname(outlook.data.data.lname)
                setLevel(outlook.data.data.Level)
                setLevel_old(outlook.data.data.Level)
                setemail(outlook.data.data.email)
                setcountry(outlook.data.data.country)
                setposter(outlook.data.data.profilepic)
                setstart_date(outlook.data.data.plan_start);
                setend_date(outlook.data.data.plan_end)
                setstart_date_old(outlook.data.data.plan_start);
                setend_date_old(outlook.data.data.plan_end)
                // window.location.href = '/dashboard/gallery';
            })
    }, [params.userId,location.state])

    const changefname = (event) => {
        setfname(event.target.value)
    }

    const changepassword = (event) => {
        setpassword(event.target.value)
    }

    const changemobileno = (event) => {
        setmobileno(event.target.value)
    }

    const changevideourl = (event) => {
        setlname(event.target.value)
    }

    const changecountry = (event) => {
        setcountry(event.target.value)
    }

    const changeemail = (event) => {
        setemail(event.target.value);
    };


    return (
        <Page title="Update User Details | SoundChatRadio">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Update User Details
                    </Typography>
                </Stack>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={12} lg={12}>
                                <Card>
                                    <CardHeader title="Update User Details" />
                                    <CardContent>
                                        <Grid container spacing={3}>

                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel shrink htmlFor="circle">First Name</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="fname"
                                                            type="text"
                                                            // label="Interview Title"
                                                            {...getFieldProps('fname')}
                                                            value={fname}
                                                            onChange={changefname}
                                                            error={Boolean(touched.fname && errors.fname)}
                                                            helperText={touched.fname && errors.fname}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel shrink htmlFor="circle">
                                                            Last Name</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="lname"
                                                            type="text"
                                                            // label="Video url"
                                                            {...getFieldProps('lname')}
                                                            value={lname}
                                                            onChange={changevideourl}
                                                            error={Boolean(touched.lname && errors.lname)}
                                                            helperText={touched.lname && errors.lname}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel shrink htmlFor="circle">
                                                            Phone No.</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="mobileno"
                                                            type="text"
                                                            // label="Interview mobilenoription"
                                                            {...getFieldProps('mobileno')}
                                                            onChange={changemobileno}
                                                            value={mobileno}
                                                            error={Boolean(touched.mobileno && errors.mobileno)}
                                                            helperText={touched.mobileno && errors.mobileno}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel shrink htmlFor="circle">
                                                            Email</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="email"
                                                            type="text"
                                                            // label="Interview mobilenoription"
                                                            {...getFieldProps('email')}
                                                            onChange={changeemail}
                                                            value={email}
                                                            error={Boolean(touched.email && errors.email)}
                                                            helperText={touched.email && errors.email}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel shrink htmlFor="circle">
                                                            Country Name</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="country"
                                                            type="text"
                                                            // label="Interview mobilenoription"
                                                            {...getFieldProps('country')}
                                                            onChange={changecountry}
                                                            value={country}
                                                            error={Boolean(touched.country && errors.country)}
                                                            helperText={touched.country && errors.country}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel shrink htmlFor="circle">
                                                            Set New Password</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="current-password"
                                                            type={showPassword ? 'text' : 'password'}
                                                            // label="Password"
                                                            onChange={changepassword}
                                                            {...getFieldProps('password')}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                                                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            error={Boolean(touched.password && errors.password)}
                                                            helperText={touched.password && errors.password}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>

                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sx={{ mt: 3 }}>
                            <Card>
                                <CardHeader title="Image For Profile" />
                                <Grid container spacing={3}>
                                    <Grid xs={12} md={3} lg={3} sx={{
                                        mt: 3, ml
                                            : 3, mb: 0, pb: 0
                                    }}>
                                        <Stack spacing={3}>
                                            <CardContent >
                                                <img src={baseurl + gallery.profilepic} alt={gallery.profilepic} width='250' height='250' />
                                            </CardContent>

                                        </Stack>
                                    </Grid>


                                    <Grid xs={12} md={6} lg={6} sx={{ mt: 3 }}>
                                        <Stack spacing={3}>
                                            <CardContent>

                                                <ImgCrop >
                                                    <Upload
                                                        action="https://app.soundchatradio.com"
                                                        className="upload-img"
                                                        listType="picture-card"
                                                        onChange={handleChange}
                                                        onPreview={onPreview}
                                                        maxCount={1}

                                                    >
                                                        + Upload New Image For Profile
                            </Upload>
                                                </ImgCrop>
                                            </CardContent>
                                        </Stack>
                                    </Grid>
                                </Grid>

                            </Card>
                        </Grid>

                        <Stack spacing={3} sx={{ mt: 4 }}>
                            <div>

                                <Button

                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Update
                                </Button>
                            </div>
                        </Stack>

                    </Form >
                </FormikProvider >
                <ToastContainer />
            </Container >
        </Page >
    );

}
