import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import editFill from '@iconify/icons-eva/edit-fill';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';


// material
import {
  Stack,
 Grid, Container, Typography
} from '@mui/material';

// material
import { Card, CardContent, styled ,Dialog} from '@material-ui/core';
import axios from 'axios';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Productitem from './Productitem'
import Page from '../components/Page';


const ProductDetails = () => {
  const navigate = useNavigate();
  if ((localStorage.getItem('token')==='' && localStorage.getItem("userid")==='0') || (localStorage.getItem('token')===null && localStorage.getItem("userid")===null)) {
    navigate('/login', { replace: true });
}
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 
  const params = useParams();
  const [gallery, setgallery] = useState([])
  const [productimage, setproductimage] = useState([])
  useEffect(() => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/productdetails`;
    axios.post(Livechaturl, { id: params.productId }, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        const outlook = response
        setgallery(outlook.data.data);
        setproductimage(outlook.data.data.productimages)
        // window.location.href = '/dashboard/gallery';
      })
  }, [params.productId])
  const deleterow = ()=>{
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/deleteproduct`;
        axios.post(Livechaturl, {
          id: params.productId,
        },
        {
         headers: {
           'x-access-token': localStorage.getItem('token'),
         }
       }).then((response) => {
          const outlook = response 
          // window.location.href = '/dashboard/products';
          if((outlook.data.status===400) || (outlook.data.status===401) || (outlook.data.status===403)){
            navigate('/login', { replace: true });
            localStorage.setItem("token", "");
            localStorage.setItem("userid", '0');
            localStorage.setItem("email", '');
            toast.warning(outlook.data.message)
        }
        else{
          navigate(`/dashboard/products`, { state: { id: 'kk'} });
          // navigate('/dashboard/interview', { replace: true });
                    toast.success(outlook.data.message)
        }
      })
      handleClose()
  }
  const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/product/';
  
  const CardMediaStyle = styled('div')({
    position: 'relative',

  });

  const CoverImgStyle = styled('img')({
    top: 0,
    width: '80',
    height: '80',
    objectFit: 'cover',
    display: 'inline-block'

  });



  return (
    <Page title=" Product Details | SoundChatRadio">

    <Container maxWidth="lg" minWidth="sm">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          Product Details
        </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Button
                        variant="contained"
                        component={RouterLink}
                        to={`/dashboard/products/update/${gallery.id}`}
                        startIcon={<Icon icon={editFill} />}
                    >
                        update Product
                        
          </Button>
        <Button
                        variant="contained"
                        onClick={handleClickOpen}
                        startIcon={<Icon icon={trash2Outline} />}
                        color="error"
                    >
                    Delete Product
                        
          </Button>
          
      </Stack>
          <Grid container spacing={3}>

            <Grid item xs={12} md={12} lg={12}>

              <Grid container spacing={3}>

                <Grid item xs={12} md={7} lg={7}>
                  <Stack spacing={3}>
                    <Card>

                      <CardContent>
                        <ul className="list-group-left">
                          <li className="list-group-item list-group-item-primary">Product Name</li>
                          {/* <li className="list-group-item list-group-item-primary">Description</li> */}
                          <li className="list-group-item list-group-item-primary">SKU Id</li>
                          <li className="list-group-item list-group-item-primary">Catogery</li>
                          {/* <li className="list-group-item list-group-item-primary">Size</li>
                          <li className="list-group-item list-group-item-primary">Color</li>
                          <li className="list-group-item list-group-item-primary">Quantity</li> */}
                          <li className="list-group-item list-group-item-primary">Price</li>
                          <li className="list-group-item list-group-item-primary">Currency</li>
                          {/* <li className="list-group-item list-group-item-primary">Published Date</li>
                          <li className="list-group-item list-group-item-primary">Sale Start Date</li>
                          <li className="list-group-item list-group-item-primary">Sale End Date</li> */}
                          <li className="list-group-item list-group-item-primary">Created Date</li>
                          <li className="list-group-item list-group-item-primary">Updated Date</li>

                        </ul>
                        <ul className="list-group-right">
                          <li className="list-group-item list-group-item-primary">{(gallery.title)?<>{gallery.title}</>:<>N/A</>}</li>
                          {/* <li className="list-group-item list-group-item-primary">{(gallery.description)?<>{gallery.description}</>:<>N/A</>}</li> */}
                          <li className="list-group-item list-group-item-primary">{(gallery.SKU)?<>{gallery.SKU}</>:<>N/A</>}</li>
                          <li className="list-group-item list-group-item-primary">{(gallery.catogery)?<>{gallery.catogery}</>:<>N/A</>}</li>
                          {/* <li className="list-group-item list-group-item-primary">{gallery.size}</li>
                          <li className="list-group-item list-group-item-primary">{gallery.color}</li>
                          <li className="list-group-item list-group-item-primary">{gallery.Quantity}</li> */}
                          <li className="list-group-item list-group-item-primary">{(gallery.Price)?<>{gallery.Price}</>:<>N/A</>}</li>
                          <li className="list-group-item list-group-item-primary">{(gallery.currency)?<>{gallery.currency}</>:<>N/A</>}</li>
                          {/* <li className="list-group-item list-group-item-primary">{moment(gallery.Published_At).format('MMMM Do YYYY')}</li>
                          <li className="list-group-item list-group-item-primary">{moment(gallery.Starts_At).format('MMMM Do YYYY')}</li>
                          <li className="list-group-item list-group-item-primary">{moment(gallery.Ends_At).format('MMMM Do YYYY')}</li> */}
                          <li className="list-group-item list-group-item-primary">{(moment(gallery.createdAt).format('MMMM Do YYYY'))?<>{moment(gallery.createdAt).format('MMMM Do YYYY')}</>:<>N/A</>}</li>
                          <li className="list-group-item list-group-item-primary">{(moment(gallery.updatedAt).format('MMMM Do YYYY'))?<>{moment(gallery.updatedAt).format('MMMM Do YYYY')}</>:<>N/A</>}</li>
                        </ul>
                      </CardContent>
                    </Card>

                  </Stack>
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                  <Stack spacing={3}>
                          {
                          (productimage.length>0)?
                          <>
                    <Carousel autoPlay='true' infiniteLoop='true' showArrows='true' showThumbs={false}>
                    {productimage.map((image) => (
                <div>
                    <img src={baseurl + image.image_name} alt='lldd' style={{height:'328px'}}/>
                </div>
                ))}
            </Carousel>
            </>
            :
            <>
        <div>
        <Card>
                        <CardContent style={{height: '332px'}}>
                          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                            Product Images Not Available
                  </Typography>
                        </CardContent>
                      </Card>
        </div>

    </>
}
                  </Stack>
                </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <Stack spacing={3}>
                    <Card>
                        <CardContent>
                          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                            Product Description
                  </Typography>
                          <span>{(gallery.description)?<>{gallery.description}</>:<>N/A</>}</span>
                        </CardContent>
                      </Card>
                    </Stack>
                  </Grid>
                <Grid item xs={12} md={12} lg={12}>
          <Productitem props={gallery.id}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
        Do You Really Want To Delete This Record ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>NO</Button>
          <Button onClick={deleterow} autoFocus color="error">
            YES
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />

    </Container>
    </Page>
  );
};

export default ProductDetails;