import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { Link as RouterLink, useNavigate, NavLink, useLocation, useParams } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';

// material
import {
    Link,
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel, Box, Grid, Container, Typography,
    InputLabel, MenuItem, FormControl, Select, Button
} from '@mui/material';

// material
import { Card, CardHeader, CardContent } from '@material-ui/core';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
        },
    },
    inputimage: {
        position: 'relative',
        left: '43px',
        padding: '0px'
    }
}));

export default function UpdateHomeGifBanner() {
    const location = useLocation();
    const params = useParams();
    const [content_type, setcontent_type] = useState('');
    const [title, settitle] = useState('');
    const [external_link, setexternal_link] = useState('');

    const [gallery, setgallery] = useState([]);
    const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/banner/';

    const navigate = useNavigate();

    if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
        navigate('/login', { replace: true });
    }
    const classes = useStyles();
    const [gallery_pics, setgallery_pics] = useState();
    const [mobileimage, setMobileimage] = useState();
    const [desktopimage, setDesktopimage] = useState();
    // const { handleSubmit, control } = useForm();

    const handleChange = ({ fileList }) => {
        setMobileimage(fileList[0].originFileObj)
    }

    const handleChange1 = ({ fileList }) => {
        setDesktopimage(fileList[0].originFileObj)
    }
    const handleChangefordesc = (event) => {
        setcontent_type(event.target.value);
    };
    const handleChangefortitle = (event) => {
        settitle(event.target.value);
    };
    const handleChangeforlink = (event) => {
        setexternal_link(event.target.value);
    };
    const LoginSchema = Yup.object().shape({
        post_title: Yup.string('title is required'),
        post_desc: Yup.string('Desc. is required'),
        external_link: Yup.string('link is required'),
    });

    const formik = useFormik({
        initialValues: {
            post_title: "",
            post_desc: "",
            external_link: ""
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            const bodyFormData = new FormData();
            bodyFormData.append('id', params.bannerId);
            bodyFormData.append('mobileimage', mobileimage);
            bodyFormData.append('desktopimage', mobileimage);
            bodyFormData.append('post_title', title);
            bodyFormData.append('post_excerpt', content_type);
            bodyFormData.append('external_link', external_link);


            const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/updatehomegif`;
            axios.post(Livechaturl, bodyFormData, {
                'Content-Type': 'multipart/form-data', headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            })
                .then((response) => {
                    const outlook = response
                    if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                        navigate('/login', { replace: true });
                        localStorage.setItem("token", "");
                        localStorage.setItem("userid", '0');
                        localStorage.setItem("email", '');
                        toast.warning(outlook.data.message)
                    }
                    else {
                        navigate('/dashboard/banner/homegifbanner', { replace: true });
                        toast.success(outlook.data.message)
                    }
                })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
    const onChange = ({ fileList }) => {
        setgallery_pics({ fileList });
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    const onAudioPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Audio(src);
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };


    useEffect(() => {
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/homegifdetails`;
        axios.post(Livechaturl, { id: params.bannerId }, { 'Content-Type': 'multipart/form-data' })
            .then((response) => {
                const outlook = response
                setgallery(outlook.data.data);
                settitle(outlook.data.data.post_title)
                setcontent_type(outlook.data.data.post_excerpt)
                setexternal_link(outlook.data.data.external_link)

                // window.location.href = '/dashboard/gallery';
            })
    }, [params.bannerId])
    return (
        <Page title="Update Banner | SoundChatRadio">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Update Gif Banner
                    </Typography>
                </Stack>

                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Stack spacing={3}>
                                    <FormControl>
                                        <TextField
                                            fullWidth
                                            autoComplete="post_title"
                                            type="text"
                                            label="Banner Title"
                                            {...getFieldProps('post_title')}
                                            value={title}
                                            onChange={handleChangefortitle}
                                            error={Boolean(touched.post_title && errors.post_title)}
                                            helperText={touched.post_title && errors.post_title}
                                        />
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Stack spacing={3}>
                                    <FormControl>
                                        <TextField
                                            fullWidth
                                            autoComplete="external_link"
                                            type="text"
                                            label="External Link"
                                            {...getFieldProps('external_link')}
                                            value={external_link}
                                            onChange={handleChangeforlink}
                                            error={Boolean(touched.external_link && errors.external_link)}
                                            helperText={touched.external_link && errors.external_link}
                                        />
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Stack spacing={3}>
                                    <FormControl>
                                        <TextField
                                            fullWidth
                                            autoComplete="post_desc"
                                            type="text"
                                            label="Banner Description"
                                            multiline
                                            rows={5}
                                            rowsMax={10}
                                            {...getFieldProps('post_desc')}
                                            value={content_type}
                                            onChange={handleChangefordesc}
                                            error={Boolean(touched.post_desc && errors.post_desc)}
                                            helperText={touched.post_desc && errors.post_desc}
                                        />
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sx={{ mt: 3 }}>
                                <Card>
                                    <CardHeader title="Image For Banner" />
                                    <Grid container spacing={3}>
                                        <Grid xs={12} md={3} lg={3} sx={{
                                            mt: 3, ml
                                                : 3, mb: 0, pb: 0
                                        }}>
                                            <Stack spacing={3}>
                                                <CardContent>
                                                    <img src={baseurl + gallery.mobile_gif} alt={gallery.mobile_gif} width='250' height='250' />
                                                </CardContent>
                                            </Stack>
                                        </Grid>


                                        <Grid xs={12} md={6} lg={6} sx={{ mt: 3 }}>
                                            <Stack spacing={3}>
                                                <CardContent>
                                                    {/* <ImgCrop grid shape='react'
                                                        aspect={4 / 2}> */}
                                                    <Upload
                                                        action="https://app.soundchatradio.com"
                                                        className="upload-img"
                                                        listType="picture-card"
                                                        onChange={handleChange}
                                                        onPreview={onPreview}
                                                        maxCount={1}

                                                    >
                                                        + Upload New Image For Banner <br />(1200 x 100 in px.)
                                                    </Upload>
                                                    {/* </ImgCrop> */}
                                                </CardContent>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                </Card>
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={12} sx={{ mt: 3 }}>
                                <Card>
                                    <CardHeader title="Image For Desktop Poster" />
                                    <Grid container spacing={3}>
                                    <Grid xs={12} md={3} lg={3} sx={{ mt: 3,ml
                                    :3,mb:0,pb:0 }}>
                                        <Stack spacing={3}>
                                            <CardContent>
                                                    <img src={baseurl + gallery.desktopbanner} alt={gallery.desktopbanner} width='250' height='250'/>
                                               </CardContent>
                                        </Stack>
                                    </Grid>


                                    <Grid xs={12} md={6} lg={6} sx={{ mt: 3 }}>
                                        <Stack spacing={3}>
                                            <CardContent>
                                                <ImgCrop >
                                                    <Upload
                                                        className="upload-img"
                                                        listType="picture-card"
                                                        onChange={handleChange1}
                                                        onPreview={onPreview}
                                                        maxCount={1}
                                                        
                                                    >
                                                        + Upload New Image For Desktop Banner
                            </Upload>
                                                </ImgCrop>
                                            </CardContent>
                                        </Stack>
                                    </Grid>
                                    </Grid>

                                </Card>
                            </Grid> */}
                        </Grid>
                        <Stack spacing={3} sx={{ mt: 4 }} >
                            <div>
                                <Button

                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Update Banner
                                </Button>
                            </div>
                        </Stack>
                    </Form>
                </FormikProvider>
                <ToastContainer />
            </Container>
        </Page>
    );
}
