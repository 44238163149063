import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';

// material
import {
    Link,
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel, Box, Grid, Container, Typography,
    InputLabel, MenuItem, FormControl, Select, Button
} from '@mui/material';

// material
import { Card, CardHeader, CardContent } from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
        },
    },
    inputimage: {
        position: 'relative',
        left: '43px',
        padding: '0px'
    }
}));

export default function AddUser() {
    const [content_type, setcontent_type] = useState('free_content');
    const [interview_type, setinterview_type] = useState('YELLOW_TENT');
    const [video_type, setvideo_type] = useState('YOUTUBE');
    const [cotegory, setCotegory] = useState([]);
    const [showPassword, setShowPassword] = useState(false);


    const handleChangeforselect = (event) => {
        setcontent_type(event.target.value);
    };

    const handleChangeforselectvideo = (event) => {
        setvideo_type(event.target.value);
    };
    const handleChangeforselect2 = (event) => {
        setinterview_type(event.target.value);
    };
    const navigate = useNavigate();
    const LoginSchema = Yup.object().shape({
        username: Yup.string().required('username is required'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        fname: Yup.string().required('first is required'),
        password: Yup.string().required('password is required'),
        mobileno: Yup.string().required('phone number is required').matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Phone number is not valid"
        ),
        country: Yup.string().required('country name is required')
    });

    const formik = useFormik({
        initialValues: {
            username: '',
            email: '',
            fname: '',
            password: '',
            mobileno: '',
            country: ''
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            if (mobileimage) {

                const bodyFormData = new FormData();
                bodyFormData.append('profilepic', mobileimage);
                // bodyFormData.append('desktopimage', desktopimage);
                bodyFormData.append('mobileno', values.username);
                bodyFormData.append('email', values.email);
                bodyFormData.append('fname', values.fname);
                bodyFormData.append('lname', values.lname);
                bodyFormData.append('username', values.mobileno);
                bodyFormData.append('country', values.country);
                bodyFormData.append('password', values.password);

                const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/signupbyadmin`;
                axios.post(Livechaturl, bodyFormData, {
                    'Content-Type': 'multipart/form-data', headers: {
                        'x-access-token': localStorage.getItem('token'),
                    }
                })
                    .then((response) => {
                        const outlook = response
                        if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                            navigate('/login', { replace: true });
                            localStorage.setItem("token", "");
                            localStorage.setItem("userid", '0');
                            localStorage.setItem("email", '');
                            toast.warning(outlook.data.message)
                        }
                        else {
                            navigate('/dashboard/user', { replace: true });
                            toast.success(outlook.data.message)
                        }
                    })
            }
            else {
                toast.warning('please upload image with extension jpeg/png.')
            }
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
        navigate('/login', { replace: true });
    }
    const classes = useStyles();
    const [gallery_pics, setgallery_pics] = useState();
    const [mobileimage, setMobileimage] = useState();
    const [desktopimage, setDesktopimage] = useState();
    // const { handleSubmit, control } = useForm();

    const handleChange = ({ fileList }) => {
        // setMobileimage(fileList[0].originFileObj)
        if (fileList[0].type === "image/png" || fileList[0].type === "image/jpeg" || fileList[0].type === "image/jpg" || fileList[0].type === "image/gif") {
            setMobileimage(fileList[0].originFileObj)
        } else {
            alert('file is not in proper format')
            navigate('/dashboard/user/adduser', { state: 'll' });
        }
    }

    const handleChange1 = ({ fileList }) => {
        setDesktopimage(fileList[0].originFileObj)
    }

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    const onAudioPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Audio(src);
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    useEffect(() => {
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/listcategory`;
        const bodyFormData = new FormData();
        axios.post(Livechaturl, {},
            {
                headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            })
            .then((response) => {
                const outlook = response
                if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                    navigate('/login', { replace: true });
                    localStorage.setItem("token", "");
                    localStorage.setItem("userid", '0');
                    localStorage.setItem("email", '');
                    toast.warning(outlook.data.message)
                }
                else {
                    setCotegory(outlook.data.data);
                }
                // setfreeContent(outlook.data.data);
            })
    }, []);
    return (
        <Page title="Add User | SoundChatRadio">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Add New User
                    </Typography>
                </Stack>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={12} lg={12}>
                                <Card>
                                    <CardHeader title="Add User Details" />
                                    <CardContent>
                                        <Grid container spacing={3}>

                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="username"
                                                            type="text"
                                                            label="User Name"
                                                            {...getFieldProps('username')}
                                                            error={Boolean(touched.username && errors.username)}
                                                            helperText={touched.username && errors.username}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="email"
                                                            type="email"
                                                            label="Email... "
                                                            {...getFieldProps('email')}
                                                            error={Boolean(touched.email && errors.email)}
                                                            helperText={touched.email && errors.email}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="fname"
                                                            type="text"
                                                            label="First Name"
                                                            {...getFieldProps('fname')}
                                                            error={Boolean(touched.fname && errors.fname)}
                                                            helperText={touched.fname && errors.fname}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="lname"
                                                            type="text"
                                                            label="Last Name"
                                                            {...getFieldProps('lname')}
                                                            error={Boolean(touched.lname && errors.lname)}
                                                            helperText={touched.lname && errors.lname}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="mobileno"
                                                            type="text"
                                                            label="Mobile No"
                                                            {...getFieldProps('mobileno')}
                                                            error={Boolean(touched.mobileno && errors.mobileno)}
                                                            helperText={touched.mobileno && errors.mobileno}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="country"
                                                            type="text"
                                                            label="Country Name"
                                                            {...getFieldProps('country')}
                                                            error={Boolean(touched.country && errors.country)}
                                                            helperText={touched.country && errors.country}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="current-password"
                                                            type={showPassword ? 'text' : 'password'}
                                                            label="Password"
                                                            {...getFieldProps('password')}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                                                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            error={Boolean(touched.password && errors.password)}
                                                            helperText={touched.password && errors.password}
                                                        />

                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} sx={{ mt: 3 }}>
                            <Card>
                                <CardHeader title="Image For Profile" />
                                <CardContent>


                                    <Stack spacing={4}>

                                        <ImgCrop >
                                            <Upload
                                                action="https://app.soundchatradio.com"
                                                className="upload-img"
                                                listType="picture-card"
                                                onChange={handleChange}
                                                onPreview={onPreview}
                                                maxCount={1}
                                            >
                                                + Upload Image For Profile Pic.
                                            </Upload>
                                        </ImgCrop>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Stack spacing={3} sx={{ mt: 4 }} >
                            <div>

                                <Button

                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Add User
                                </Button>
                            </div>
                        </Stack>


                    </Form>
                </FormikProvider >
                <ToastContainer />
            </Container >
        </Page >
    );
}
