import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { Link as RouterLink, useNavigate, NavLink, useLocation, useParams } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import TextField from '@material-ui/core/TextField';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';

import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
// import Select from "react-select";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
// import FormControl from '@mui/material/FormControl';


// material
import {
    Link,
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel, Box, Grid, Container, Typography,
    InputLabel, MenuItem, FormControl, Select, Button
} from '@mui/material';

// material
import { Card, CardHeader, CardContent, Paper } from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';


export default function AddCoupon() {
    const location = useLocation();
    const params = useParams();
    const [amountoff, setamountoff] = useState('');
    const [percentoff, setpercentoff] = useState('');
    const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/';

    const navigate = useNavigate();
    const LoginSchema = Yup.object().shape({
        coupon_code: Yup.string().required('coupon code is required'),
        name: Yup.string().required('coupon name is required')
    });

    const formik = useFormik({
        initialValues: {
            coupon_code:'',
            name: '',
            percent_off: '',
            amount_off: ''
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            const bodyFormData = {};
            bodyFormData.id_plan = params.planId;
            bodyFormData.coupon_code = values.coupon_code;
            bodyFormData.name = values.name;
            bodyFormData.amount_off = amountoff;
            bodyFormData.percent_off = percentoff;
            bodyFormData.status = '1';
            const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/addcoupon`;
            axios.post(Livechaturl, bodyFormData, {
                'Content-Type': 'multipart/form-data', headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            })
                .then((response) => {
                    const outlook = response
                    if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                        navigate('/login', { replace: true });
                        localStorage.setItem("token", "");
                        localStorage.setItem("userid", '0');
                        localStorage.setItem("email", '');
                        toast.warning(outlook.data.message)
                    }
                    else {
                        navigate(`/dashboard/plan`, { replace: true });

                        toast.success(outlook.data.message)
                    }
                })
        }
    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
        navigate('/login', { replace: true });
    }
    // const { handleSubmit, control } = useForm();

    const handledisableforamount = (event)=>{
        const amount = event.target.value
            setamountoff(0)
            setpercentoff(amount)
    }

    const handledisableforpercent = (event)=>{
        const percent = event.target.value
            setpercentoff(0)
            setamountoff(percent)
    }

    return (
        <Page title="Add Coupon Details | SoundChatRadio">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                    Add Coupon Details
                    </Typography>
                </Stack>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={12} lg={12}>
                                <Card>
                                    <CardHeader title="Add Coupon Details" />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                    <TextField
                                                            fullWidth
                                                            autoComplete="name"
                                                            type="text"
                                                            label="Coupon name"
                                                            {...getFieldProps('name')}
                                                            error={Boolean(touched.name && errors.name)}
                                                            helperText={touched.name && errors.name}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                    <TextField
                                                            fullWidth
                                                            autoComplete="coupon_code"
                                                            type="text"
                                                            label="Coupon Code"
                                                            {...getFieldProps('coupon_code')}
                                                            error={Boolean(touched.coupon_code && errors.coupon_code)}
                                                            helperText={touched.coupon_code && errors.coupon_code}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                    <TextField
                                                            fullWidth
                                                            autoComplete="amount_off"
                                                            type="number"
                                                            label="amount off"
                                                            {...getFieldProps('amount_off')}
                                                            inputProps={{
                                                                inputMode: 'numeric', pattern: '[0-9]*',min: 0
                                                              }}
                                                            onChange={handledisableforpercent}
                                                            value={amountoff}
                                                            error={Boolean(touched.amount_off && errors.amount_off)}
                                                            helperText={touched.amount_off && errors.amount_off}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                    <TextField
                                                            fullWidth
                                                            autoComplete="percent_off"
                                                            type="number"
                                                            label="% off"
                                                            {...getFieldProps('percent_off')}
                                                            inputProps={{
                                                                inputMode: 'numeric', pattern: '[0-9]*',maxLength: 3,min: 0,max:100
                                                              }}
                                                            onChange={handledisableforamount}
                                                            value={parseInt(percentoff,10)}
                                                            error={Boolean(touched.percent_off && errors.percent_off)}
                                                            helperText={touched.percent_off && errors.percent_off}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Stack spacing={3} sx={{ mt: 4 }}>
                            <div>

                                <Button

                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Add Coupon
                                </Button>
                            </div>
                        </Stack>

                    </Form >
                </FormikProvider >
                <ToastContainer />
            </Container >
        </Page >
    );

}
