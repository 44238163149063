import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { Link as RouterLink, useNavigate, NavLink, useLocation, useParams } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import TextField from '@material-ui/core/TextField';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';

import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
// import Select from "react-select";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
// import FormControl from '@mui/material/FormControl';


// material
import {
    Link,
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel, Box, Grid, Container, Typography,
    InputLabel, MenuItem, FormControl, Select, Button
} from '@mui/material';

// material
import { Card, CardHeader, CardContent, Paper } from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
        },
    },
    inputimage: {
        position: 'relative',
        left: '43px',
        padding: '0px'
    }
}));

export default function UpdateAdmin(props) {
    const location = useLocation();
    const params = useParams();
    const [gallery, setgallery] = useState([])
    const [title, settitle] = useState('')
    const [desc, setdesc] = useState('')
    const [video_url, setvideo_url] = useState('')
    const [video_type, setvideo_type] = useState('')
    const [content_type, setcontent_type] = useState('');
    const [interview_type, setinterview_type] = useState('');
    const [poster, setposter] = useState('');
    const [cotegory, setCotegory] = useState([]);




    const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/interviews/';

    const navigate = useNavigate();
    const LoginSchema = Yup.object().shape({
        post_content: Yup.string('user name is required'),
        post_title: Yup.string('email is required'),
        video_url: Yup.string('password is required')
            .matches(
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
                "URL Link is not Valide"
            ),
    });

    const formik = useFormik({
        initialValues: {
            post_content: '',
            post_title: '',
            video_url: '',
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            const bodyFormData = new FormData();
            bodyFormData.append('id', params.adminId);
            // bodyFormData.append('desktopimage', desktopimage);
            bodyFormData.append('username', title);
            bodyFormData.append('email', desc);
            bodyFormData.append('password', video_url);
            // bodyFormData.append('admin_type', video_type);

            const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/updateadmin`;
            axios.post(Livechaturl, bodyFormData, {
                'Content-Type': 'multipart/form-data', headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            })
                .then((response) => {
                    const outlook = response
                    if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                        navigate('/login', { replace: true });
                        localStorage.setItem("token", "");
                        localStorage.setItem("userid", '0');
                        localStorage.setItem("email", '');
                        toast.warning(outlook.data.message)
                    }
                    else {
                        navigate('/dashboard/adminlist', { replace: true });
                        toast.success(outlook.data.message)
                    }
                })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
        navigate('/login', { replace: true });
    }
    const classes = useStyles();
    const [gallery_pics, setgallery_pics] = useState();
    const [mobileimage, setMobileimage] = useState('');
    const [desktopimage, setDesktopimage] = useState('');
    // const { handleSubmit, control } = useForm();

    const handleChange = ({ fileList }) => {
        setMobileimage(fileList[0].originFileObj)
    }

    const handleChange1 = ({ fileList }) => {
        setDesktopimage(fileList[0].originFileObj)
    }

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    const onAudioPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Audio(src);
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    useEffect(() => {
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/getadmin`;
        axios.post(Livechaturl, { id: params.adminId }, {
            headers: {
              'x-access-token': localStorage.getItem('token'),
            }})
            .then((response) => {
                const outlook = response
                setgallery(outlook.data.data);
                settitle(outlook.data.data.username)
                setdesc(outlook.data.data.email);
                // setvideo_url(outlook.data.data.password)
                // setvideo_type(outlook.data.data.admin_type)

                // window.location.href = '/dashboard/gallery';
            })
    }, [params.adminId])

    const changetitle = (event) => {
        settitle(event.target.value)
    }

    const changedesc = (event) => {
        setdesc(event.target.value)
    }

    const changevideourl = (event) => {
        setvideo_url(event.target.value)
    }

    const handleChangeforselect = (event) => {
        setcontent_type(event.target.value);
    };

    const handleChangeforselectvideo = (event) => {
        setvideo_type(event.target.value);
    };


    const handleChangeforselect2 = (event) => {
        setinterview_type(event.target.value);
    };
    return (
        <Page title="Update Interview | SoundChatRadio">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Update Admin Details
                    </Typography>
                </Stack>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={12} lg={12}>
                                <Card>
                                    <CardHeader title="Update Admin Details" />
                                    <CardContent>
                                        <Grid container spacing={3}>

                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel shrink htmlFor="circle"> User Name</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="post_title"
                                                            type="text"
                                                            // label="Interview Title"
                                                            {...getFieldProps('post_title')}
                                                            value={title}
                                                            onChange={changetitle}
                                                            error={Boolean(touched.post_title && errors.post_title)}
                                                            helperText={touched.post_title && errors.post_title}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel shrink htmlFor="circle">
                                                            Email</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="post_content"
                                                            type="text"
                                                            // label="Interview Description
                                                            {...getFieldProps('post_content')}
                                                            onChange={changedesc}
                                                            value={desc}
                                                            error={Boolean(touched.post_content && errors.post_content)}
                                                            helperText={touched.post_content && errors.post_content}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel shrink htmlFor="circle">
                                                            Password</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="video_url"
                                                            type="text"
                                                            // label="Video url"
                                                            {...getFieldProps('video_url')}
                                                            value={video_url}
                                                            onChange={changevideourl}
                                                            error={Boolean(touched.video_url && errors.video_url)}
                                                            helperText={touched.video_url && errors.video_url}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Stack spacing={3} sx={{ mt: 4 }}>
                            <div>

                                <Button

                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Update
                                </Button>
                            </div>
                        </Stack>

                    </Form >
                </FormikProvider >
                <ToastContainer />
            </Container >
        </Page >
    );

}
