import React, { useState, useEffect } from 'react';

import { useForm, } from 'react-hook-form';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack, Grid, Container, Typography, Avatar, Button,Dialog
} from '@mui/material';

// material
import { Card, CardContent, styled } from '@material-ui/core';
import axios from 'axios';
import {Link as RouterLink, useNavigate, useParams,useLocation } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Page from '../components/Page';


const SubscriberDetails = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { handleSubmit } = useForm();
  const stripePromise = loadStripe('pk_test_51JJBMbSJ0AUE5zrJTcMlsBlZy8TKuVZjzSZTb2sDKfni7wUEJiVaz86jkrk92cB87MKSzGl7z5fDQyIZr4CIlCef00MTBTd9zU');
  const params = useParams();
  const [gallery, setgallery] = useState([])
  const [subsciption, setsubsciption] = useState([])
  const [Level, setLevel] = useState('')
  const cancleplan = (event) => {
  
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/cancelsubscriberplanbytheadmin`;
        axios.post(Livechaturl, { user_id: params.userId,subscription_id: subsciption.subscription_id},
          {
              headers: {
                  'x-access-token': localStorage.getItem('token'),
              }
          })
            .then((response) => {
                const outlook = response
                // navigate('/dashboard/interview', { replace: true });
                // window.location.href = '/dashboard/interview';
                // toast.success(outlook.data.message)
                if((outlook.data.status===400) || (outlook.data.status===401) || (outlook.data.status===403)){
                    navigate('/login', { replace: true });
                    localStorage.setItem("token", "");
                    localStorage.setItem("userid", '0');
                    localStorage.setItem("email", '');
                    toast.warning(outlook.data.message)
                }
                else{
                navigate(`/dashboard/subscriber/details/${params.userId}`, { state: { id: event.target.name} });
                toast.success("Plan Cancelled Succefully")
                }
            })
            handleClose()
  };
  useEffect(() => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/getprofilebyadmin`;
    axios.post(Livechaturl, { user_id: params.userId },
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      })
      .then((response) => {
        const outlook = response
        setgallery(outlook.data.data);
      })
    const profileurl = `https://app.soundchatradio.com:3000/api/v1/auth/subscriberdetailsbyadmin`;
    axios.post(profileurl, { user_id: params.userId },
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      })
      .then((response) => {
        const outlook = response
        setsubsciption(outlook.data.data);
        setLevel(outlook.data.data.stripe_status)
      })
  }, [params.userId,state])
  const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/';
  const formik = useFormik({
    initialValues: {
      post_content: "",
      post_title: "",
      post_excerpt: "",
      post_name: "",
      post_type: "",
      video_url: "",
    },

  });

  if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
    navigate('/login', { replace: true });
  }
  const CardMediaStyle = styled('div')({
    position: 'relative',

  });

  const CoverImgStyle = styled('img')({
    top: 0,
    width: '80',
    height: '80',
    objectFit: 'cover',
    display: 'inline-block'

  });

  return (
    <Page title="Subscriber Details | SoundChatRadio">

      <Container maxWidth="lg" minWidth="sm">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Subscriber Details
        </Typography>
        <Button
                        variant="contained"
                        component={RouterLink}
                        to={`/dashboard/subscriber/updatesubscriber/${params.userId}`}
                    >
                        Update The Plan
                        
          </Button>
        {(Level==='ACTIVE') ? <Button
                        variant="contained"
                        onClick={handleClickOpen}
                        color="error"
                    >
                        Cancel The Plan
                        
          </Button> : <>
                        
          </>}
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>

              <Grid item xs={12} md={12} lg={12}>

                <Grid container spacing={3}>

                  <Grid item xs={12} md={6} lg={6}>
                    <Stack spacing={3}>
                      <Card>

                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            Personal Details
        </Typography>

                          <ul className="list-group-left">
                            <li className="list-group-item list-group-item-primary">Profile Pic.</li>
                            <li className="list-group-item list-group-item-primary">User Name</li>
                            <li className="list-group-item list-group-item-primary">Email</li>
                            <li className="list-group-item list-group-item-primary">Full Name</li>
                            <li className="list-group-item list-group-item-primary">Mobile No.</li>
                            <li className="list-group-item list-group-item-primary">User Type</li>
                            <li className="list-group-item list-group-item-primary">Country</li>
                            <li className="list-group-item list-group-item-primary">Joined Date</li>
                            {/* <li className="list-group-item list-group-item-primary">Plan Start Date</li>
                            <li className="list-group-item list-group-item-primary">Plan End Date</li> */}
                          </ul>
                          <ul className="list-group-right">
                            <li>
                              <Avatar alt={gallery.username} src={baseurl + gallery.profilpic} /></li>
                            <li className="list-group-item list-group-item-primary">{(gallery.username) ? <>{gallery.username}</> : <>N/A</>}</li>
                            {/* <li className="list-group-item list-group-item-primary">{(gallery.fname)?<>{gallery.fname}</>:<>N/A</>}</li> */}
                            <li className="list-group-item list-group-item-primary">{(gallery.email) ? <>{gallery.email}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(gallery.fname) ? <>{gallery.fname} {gallery.lname}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(gallery.mobileno) ? <>{gallery.mobileno}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(gallery.Level) ? <>{gallery.Level}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(gallery.country) ? <>{gallery.country}</> : <>N/A</>}</li>
                            {/* <li className="list-group-item list-group-item-primary">{gallery.post_content}</li> */}
                            {/* <li className="list-group-item list-group-item-primary">{gallery.post_author}</li> */}
                            <li className="list-group-item list-group-item-primary">{(gallery.join_date) ? <>{moment(gallery.join_date).format('MMMM Do YYYY')}</> : <>N/A</>}</li>
                            {/* <li className="list-group-item list-group-item-primary">{(moment(gallery.plan_start).format('MMMM Do YYYY'))?<>{moment(gallery.plan_start).format('MMMM Do YYYY')}</>:<>N/A</>}</li> */}
                            {/* <li className="list-group-item list-group-item-primary">{(moment(gallery.plan_end).format('MMMM Do YYYY'))?<>{moment(gallery.plan_end).format('MMMM Do YYYY')}</>:<>N/A</>}</li> */}
                          </ul>
                        </CardContent>
                      </Card>

                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Stack spacing={3}>
                      <Card style={{height: '405px'}}>

                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            Plan Details
        </Typography>
                          <ul className="list-group-left">
                            <li className="list-group-item list-group-item-primary">Subscription Id</li>
                            <li className="list-group-item list-group-item-primary">Subscription Type</li>
                            <li className="list-group-item list-group-item-primary">Plan Type</li>
                            <li className="list-group-item list-group-item-primary">Currency</li>
                            <li className="list-group-item list-group-item-primary">Paid Amount</li>
                            <li className="list-group-item list-group-item-primary">Stripe Status</li>
                            {/* <li className="list-group-item list-group-item-primary">Stripe Status</li> */}
                            <li className="list-group-item list-group-item-primary">Plan Start Date</li>
                            <li className="list-group-item list-group-item-primary">Plan End Date</li>
                          </ul>
                          <ul className="list-group-right">
                            <li className="list-group-item list-group-item-primary">{(subsciption.subscription_id) ? <>{subsciption.subscription_id}</> : <>N/A</>}</li>
                            {/* <li className="list-group-item list-group-item-primary">{(gallery.fname)?<>{gallery.fname}</>:<>N/A</>}</li> */}
                            <li className="list-group-item list-group-item-primary">{(subsciption.plan_type) ? <>{subsciption.plan_type}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(subsciption.plan_level) ? <>{subsciption.plan_level}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(subsciption.currency_type) ? <>{subsciption.currency_type}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(subsciption.amount) ? <>{subsciption.amount}</> : <>0</>}</li>
                            <li className="list-group-item list-group-item-primary">{(subsciption.stripe_status) ? <>{subsciption.stripe_status}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(moment(subsciption.plan_active).format('MMMM Do YYYY')) ? <>{moment(subsciption.plan_active).format('MMMM Do YYYY')}</> : <>N/A</>}</li>
                            <li className="list-group-item list-group-item-primary">{(moment(subsciption.plan_expired).format('MMMM Do YYYY')) ? <>{moment(subsciption.plan_expired).format('MMMM Do YYYY')}</> : <>N/A</>}</li>
                          </ul>
                        </CardContent>
                      </Card>
                    </Stack>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Form>
        </FormikProvider>


        <ToastContainer />
        <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            Do You Really Want To Cancel The Plan ?
        </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>NO</Button>
            <Button onClick={cancleplan} autoFocus color="error">
              YES
          </Button>
          </DialogActions>
        </Dialog>
        <ToastContainer />
      </div>
      </Container>

    </Page>
  );
};

export default SubscriberDetails;