import React, { useState, useEffect }      from 'react';

import Button                              from '@material-ui/core/Button';
import AccountCircleIcon                   from '@mui/icons-material/AccountCircle';
import PersonPinCircleSharpIcon            from '@mui/icons-material/PersonPinCircleSharp';
import CloudDownloadIcon                   from '@mui/icons-material/CloudDownload';
import CalendarTodayIcon                   from '@mui/icons-material/CalendarToday';
import { useFormik, Form, FormikProvider } from 'formik';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import * as Yup from 'yup';
import "./order-details.css"

// material
import {
    Stack, Grid, Container, Typography,
    InputLabel, MenuItem, FormControl, Select, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Avatar
} from '@mui/material';

// material
import { Card, CardContent, styled }                    from '@material-ui/core';
import axios from 'axios';
import { useNavigate, useParams, Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Page from '../components/Page';

export default function PaymentStatus() {



    const token    = localStorage.getItem("token");
    const navigate = useNavigate();

    const [ordersucceed, setordersucceed] = useState({});
    const { promiseInProgress }           = usePromiseTracker();
    const showToastMessageone = (data) => {
        toast.error(data, {
            position: toast.POSITION.TOP_CENTER,
        });
    };
    const [searchParams, setSearchParams] = useSearchParams();
    //   console.log(searchParams.get("payment_intent"))
    const [status, setStatus] = useState();
    useEffect(() => {
        if (searchParams.get("payment_intent")) {
            const config = {
                method: "get",
                url: `https://api.stripe.com/v1/payment_intents/${searchParams.get(
                    "payment_intent"
                )}`,
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_STRIP_KEY}`,
                },
            };

            axios(config)
                .then((response) => {
                    if (response.data.status === "succeeded") {
                        localStorage.removeItem("data");
                    }
                    setStatus(response.data.status);
                    const data = JSON.stringify({
                        payment_status   : response.data.status,
                        paymentintent_id : response.data.id,
                        order_id         : response.data.metadata.order_id,
                    });

                    const config = {
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}/creatproducteorderbyadmin`,
                        headers: {
                            "x-access-token": localStorage.getItem("token"),
                            "Content-Type": "application/json",
                        },
                        data,
                    };
                    trackPromise(
                        axios(config)
                            .then((response) => {
                                // console.log(response)
                                if (response.data.status === 400) {
                                    showToastMessageone(response.data.message);
                                    localStorage.removeItem("token");
                                    localStorage.removeItem("id");
                                    navigate("/Login", { replace: true });
                                } else if (response.data.status === 304) {
                                    showToastMessageone(response.data.message);
                                } else {
                                    setordersucceed(response.data.data);
                                    // console.log(response.data.data)


                                    setOrderData({
                                        id                      : response.data.data.id,
                                        user                    : response.data.data.user,
                                        full_name               : response.data.data.full_name,
                                        invoice_file_name       : response.data.data.invoice_file_name,
                                        createdAt               : response.data.data.createdAt,
                                        "shipping_phone_number" : response.data.data.shipping_phone_number,
                                        shipping_address        : response.data.data.shipping_address,
                                        postcode                : response.data.data.postcode,
                                        city                    : response.data.data.city,
                                        country                 : response.data.data.country,
                                        payment_mode            : response.data.data.payment_mode,
                                        trasaction_status       : response.data.data.trasaction_status,
                                        user_id                 : response.data.data.user_id

                                    });

                                    // console.log(response.data.data)
                                    setorder_trasaction_ref(response.data.data.order_trasaction_ref)
                                    setdelivery_fee(response.data.data.delivery_fee)
                                    setProductorderitems(response.data.data.productorderitems);
                                    setTotalQty(response.data.data.total_quantity);
                                    setTotalAmount(response.data.data.total_amount)

                                    setcontent_type(response.data.data.tracking_status)
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [searchParams.get("payment_intent")]);

    const [orderData, setOrderData] = useState({
        id                    :"",
        user                  : "",
        full_name             : "",
        invoice_file_name     : "",
        createdAt             : "",
        shipping_phone_number : "",
        shipping_address      : "",
        postcode              : "",
        city                  : "",
        country               : "",
        payment_mode          : "",
        trasaction_status     : "",
        user_id               : ""
    })
    const [totalQty, setTotalQty]         = useState("")
    const [totalAmount, setTotalAmount]   = useState("")
    const [productorderitems, setProductorderitems] = useState([]);
    const [content_type, setcontent_type] = useState('');
    const [delivery_fee, setdelivery_fee] = useState('');
    const [order_trasaction_ref, setorder_trasaction_ref] = useState('');

    //   const handleDownload = (pathname) => {
    //     const link = document.createElement("a");
    //     link.download = "SoundchatRadioInvoice";

    //     link.href = pathname;
    //     link.target = "_blank";

    //     link.click();
    //   };
    const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/product/';
    const LoginSchema = Yup.object().shape({

    });

    const formik = useFormik({
        initialValues: {
            post_content: "",
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
        }
    });

    const { isSubmitting, handleSubmit } = formik;

    return (
        <Page title="Payment Status | SoundChatRadio">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Payment Status
                    </Typography>
                </Stack>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <div className="order-details-main-head">
                            <Container>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Card className="order-details">
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item md={6} sm={6} xs={6}>
                                                        <img style={{ "width": "121px" }} src="/static/soundpic.png" className="detail-logo" alt="err" />
                                                    </Grid>
                                                    <Grid item md={6} sm={6} xs={6} pb={5} >
                                                        <div className="order-same-detaul" style={{"textAlign": "end", }}>
                                                            <Typography variant="subtitle1" className="text-muted">
                                                            Order Date: <span>
                                                            {moment(orderData.createdAt).format("MMM D, YYYY")}                                           </span> </Typography>
                                                            <Typography variant="h6">
                                                                Order Id: <span>
                                                                    {`#SOUNDCHAT${orderData.id}`}
                                                                </span>
                                                            </Typography>
                                                            <a href={`https://app.soundchatradio.com/soundradiobackend/images/pdf/${orderData.invoice_file_name}`} target='_blank' rel="noreferrer" >
                                                            <Button color='success' size="large" variant="contained" style={{marginTop:"12px"}} > 
                                                            Invoice   <CloudDownloadIcon style={{ "marginLeft": "13px", "marginTop": "-2px" }} />
                                                             </Button>
                                                         </a>
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                {/* Transaction and Order Status */}
                                                <Grid container>
                                                    <Grid item md={6} sm={6} xs={6}>
                                                        <div className="order-currency" style={{ "marginTop": "22px" }}>
                                                            <Typography variant="h6">Transaction Status</Typography>
                                                            <Typography variant="body1" className='text-muted-span' style={{ "color": "green" }}>{orderData.trasaction_status === "succeeded" ? (
                                                                <div>Success</div>
                                                            ) : null}</Typography>
                                                        </div>
                                                    </Grid>  
                                                   <Grid item md={3}  sm={2} lg={3} />
                                                    
                                                
                                                     <Grid item md={3} xs={6} sm={4} lg={3} style={{marginTop:"11px"}}  >
                                                     <Stack spacing={2}>
                                                                    <FormControl>
                                                                        <InputLabel id="demo-simple-select-helper-label">Order Status </InputLabel>
                                                                        <Select disabled style={{ "fontSize": "0.8rem" }}
                                                                            labelId="demo-simple-select-helper-label"
                                                                            id="demo-simple-select-helper"
                                                                            value={content_type}
                                                                            label="Status"
                                                                        //   onChange={handleChangeforselect}
                                                                        >
                                                                            <MenuItem value="ORDER_CONFIRM">ORDER_CONFIRM</MenuItem>
                                                                            <MenuItem value="ORDER_CANCEL">ORDER_CANCEL</MenuItem>
                                                                            <MenuItem value="ORDER_PENDING">ORDER_PENDING</MenuItem>
                                                                            <MenuItem value="ORDER_DISPATCHED">ORDER_DISPATCHED</MenuItem>
                                                                            <MenuItem value="ORDER_READY">ORDER_READY</MenuItem>
                                                                            <MenuItem value="ORDER_ON_WAY">ORDER_ON_WAY</MenuItem>
                                                                            <MenuItem value="ORDER_DELIVERED">ORDER_DELIVERED</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Stack>
                                                    </Grid>

                                                </Grid>

                                                <hr className="mt-2 mb-0" />

                                                {/* Shipping Information */}
                                                <Grid container spacing={2} pb={4} pt={4}>
                                                    <Grid item md={5} sm={4} xs={12}>
                                                        <div className="payment-methode">
                                                            <Typography variant="h3" className="main-text-oder-details">SHIPPING INFORMATION</Typography>

                                                            <span className="text-muted">Name: </span>  <span className='text-muted-span'>{orderData.full_name} </span><br />
                                                            <span className="text-muted">Phone Number: </span> <span className='text-muted-span'> {orderData.shipping_phone_number}</span> <br />
                                                            <span className="text-muted">Address: </span>  <span className='text-muted-span'>{orderData.shipping_address}</span><br />

                                                        </div>
                                                    </Grid>

                                                    {/* Payment Method */}
                                                    <Grid item md={4} sm={4} xs={12}>
                                                        <div className=" new">
                                                            <Typography variant="h3" className="main-text-oder-details">PAYMENT METHOD</Typography>
                                                            {/* Payment details */}
                                                            <p className="mb-1 payment-methode">
                                                                <span className="text-muted">Payment Type: </span>{" "}
                                                                <span className='text-muted-span'> {orderData.payment_mode}</span>
                                                            </p>
                                                        </div>
                                                    </Grid>

                                                    {/* Order Summary */}
                                                    <Grid item md={3} sm={4} xs={12} >
                                                        <div className="new-1 payment-methode">
                                                            <Typography variant="h3" className="main-text-oder-details">ORDER SUMMARY</Typography>
                                                            {/* 
                                                            <span className="text-muted">Discount: </span>
                                                            <span className='text-muted-span'> $  0.0 %</span> <br /> */}
                                                            <span className="text-muted">Shipping Charge: </span>
                                                            <span className='text-muted-span'> $ {parseFloat(delivery_fee).toFixed(2)}</span> <br />
                                                            {/* <span className="text-muted">Sub - Total: </span> $32,432 <br /> */}
                                                            <span className="text-muted">Total Amount: </span>
                                                            <span className='text-muted-span'> $ {parseFloat(totalAmount).toFixed(2)} </span> <br />
                                                            {/* <span className="text-muted">Grand Total: </span> $234,234 <br /> */}

                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                <hr className="mt-2 mb-0" />

                                                {/* Table */}
                                                <Grid container>
                                                    <Grid item   sm={12} md={12}>
                                                        <div className="table-responsive">
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Image</TableCell>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell>Unit Price</TableCell>
                                                                        <TableCell>Qty.</TableCell>
                                                                        <TableCell>Total Amount</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {productorderitems.map((item, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>
                                                                                <div className="order-img-new">
                                                                                    <img src={`${baseurl}${item.product.image}`} alt="{item.product.title}" />
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <div className="order-new-site-1">
                                                                                    <Typography variant="h6" className="order-bttn text-muted-span">{item.product.title}</Typography>
                                                                                    {/* <Typography variant="body2" className='text-muted-span'>Official Rumble Talk T-Shirt, powered by SoundChat Radio.</Typography> */}
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell className='text-muted-span' >$  {parseFloat(item.product.Price).toFixed(2)}</TableCell>
                                                                            <TableCell className='text-muted-span' >   {totalQty}          </TableCell>
                                                                            <TableCell className='text-muted-span' > $  {parseFloat(parseFloat(item.product_quantity) * parseFloat(item.product.Price)).toFixed(2)}      </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            <Link to="/dashboard/productorder">
                                            <Button style={{"float":"right","margin":"20px" }} variant="contained" color="primary" >View Order List</Button>
                                            </Link>
                                         

                                        </Card>
                                    
                                    </Grid>
                         
                                </Grid>
                              
                            </Container>
                        </div>


                    </Form>
                </FormikProvider>

                <ToastContainer />
            </Container>
        </Page>
    );
}
