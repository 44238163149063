import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { Link as RouterLink, useNavigate, NavLink, useLocation, useParams } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import TextField from '@material-ui/core/TextField';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';

import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
// import Select from "react-select";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
// import FormControl from '@mui/material/FormControl';


// material
import {
    Link,
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel, Box, Grid, Container, Typography,
    InputLabel, MenuItem, FormControl, Select, Button
} from '@mui/material';

// material
import { Card, CardHeader, CardContent, Paper } from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
        },
    },
    inputimage: {
        position: 'relative',
        left: '43px',
        padding: '0px'
    }
}));

export default function UpdateSubscriber(props) {
    const location = useLocation();
    const params = useParams();
    const [gallery, setgallery] = useState([])

    const [Level, setLevel] = useState('')
    const [Level_old, setLevel_old] = useState('')
    const [amount, setamount] = useState('');
    const [oldamount, setoldamount] = useState('');
    const [poster, setposter] = useState('');
    const [cotegory, setCotegory] = useState([]);
    const [start_date, setstart_date] = useState(null);
    const [end_date, setend_date] = useState(null);
    const [start_date_old, setstart_date_old] = useState(null);
    const [end_date_old, setend_date_old] = useState(null);
    const [password, setpassword] = useState('');




    const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/';

    const navigate = useNavigate();
    const LoginSchema = Yup.object().shape({

    });

    const formik = useFormik({
        initialValues: {
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            const bodyFormData = {};
            bodyFormData.user_id = params.subscriberId;
            bodyFormData.amount = amount;
            bodyFormData.plan_level = Level;
            bodyFormData.payment_status = 'active';
            bodyFormData.stripe_status = 'ACTIVE';
            bodyFormData.currentdate = moment(start_date).format('YYYY-MM-DD HH:mm:ss');
            bodyFormData.expireddate = moment(end_date).format('YYYY-MM-DD HH:mm:ss');
            bodyFormData.canceldate = moment(end_date).format('YYYY-MM-DD HH:mm:ss');
            const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/updatesubscriberplanbyadmin`;
            axios.post(Livechaturl, bodyFormData, {
                'Content-Type': 'multipart/form-data', headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            })
                .then((response) => {
                    const outlook = response
                    if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                        navigate('/login', { replace: true });
                        localStorage.setItem("token", "");
                        localStorage.setItem("userid", '0');
                        localStorage.setItem("email", '');
                        toast.warning(outlook.data.message)
                    }
                    else {
                        navigate(`/dashboard/subscriber/details/${params.subscriberId}`, { replace: true });
                        toast.success(outlook.data.message)
                    }
                })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
        navigate('/login', { replace: true });
    }
    const classes = useStyles();
    const [gallery_pics, setgallery_pics] = useState();
    const [mobileimage, setMobileimage] = useState('');
    const [desktopimage, setDesktopimage] = useState('');
    // const { handleSubmit, control } = useForm();

    const handleChange = ({ fileList }) => {
        setMobileimage(fileList[0].originFileObj)
    }

    const handleChange1 = ({ fileList }) => {
        setDesktopimage(fileList[0].originFileObj)
    }

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    const onAudioPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Audio(src);
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    useEffect(() => {
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/subscriberdetailsbyadmin`;
        axios.post(Livechaturl, { user_id: params.subscriberId },
            {
                headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            }).then((response) => {
                const outlook = response
                setgallery(outlook.data.data);
                setLevel(outlook.data.data.plan_level)
                setLevel_old(outlook.data.data.plan_level)
                setamount(outlook.data.data.amount)
                setoldamount(outlook.data.data.amount)
                setstart_date(outlook.data.data.plan_active);
                setend_date(outlook.data.data.plan_expired)
                setstart_date_old(outlook.data.data.plan_active);
                setend_date_old(outlook.data.data.plan_expired)
                // window.location.href = '/dashboard/gallery';
            })
    }, [params.subscriberId])


    const changeamount = (event) => {
        if(Level==='Freemium'){
            setamount('0')
        }
        if(Level==='Premium Plus'){
            setamount(9.99);
        }
        if(Level==='Premium Access'){
            setamount(4.99);
        }
        if(Level==='Platinum Access'){
            setamount(49.99);
        }
    }

 
    const handleChangeforselectlevel = (event) => {
        setLevel(event.target.value);
        if(Level_old===event.target.value){
            setstart_date(start_date_old);
            setend_date(end_date_old);
            if(event.target.value==='Freemium'){
                setamount('0');
            }
            if(event.target.value==='Premium Plus'){
                setamount(9.99);
            }
            if(event.target.value==='Premium Access'){
                setamount(4.99);
            }
            if(event.target.value==='Platinum Access'){
                setamount(49.99);
                
            }
        }
        else{
            if(event.target.value==='Freemium'){
                setamount('0');
                setstart_date(moment().toDate());
                setend_date(moment().add(10, 'years').toDate())
                
            }
            if(event.target.value==='Premium Plus'){
                setamount(9.99);
                setstart_date(moment().toDate());
                setend_date(moment().add(3, 'month').toDate())
                
            }
            if(event.target.value==='Premium Access'){
                setamount(4.99);
                setstart_date(moment().toDate());
                setend_date(moment().add(1, 'month').toDate())
                
            }
            if(event.target.value==='Platinum Access'){
                setamount(49.99);
                setstart_date(moment().toDate());
                setend_date(moment().add(1, 'years').toDate())
                
            }
            
    }
    };

    return (
        <Page title="Update Subscription Details | SoundChatRadio">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Update Subscription Details
                    </Typography>
                </Stack>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={12} lg={12}>
                                <Card>
                                    <CardHeader title="Update Subscription Details" />
                                    <CardContent>
                                        <Grid container spacing={3}>

                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel id="demo-simple-select-helper-label">Plan type </InputLabel>
                                                        <Select

                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            label="Video Type"
                                                            onChange={handleChangeforselectlevel}
                                                            value={Level}
                                                        // defaultValue = {email}

                                                        >
                                                            <MenuItem value='Freemium'>Freemium</MenuItem>
                                                            <MenuItem value='Premium Access'>Premium Access</MenuItem>
                                                            <MenuItem value='Premium Plus'>Premium Plus</MenuItem>
                                                            <MenuItem value='Platinum Access'>Platinum Access</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel shrink htmlFor="circle">Amount</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="amount"
                                                            type="text"
                                                            // label="Interview Title"
                                                            {...getFieldProps('amount')}
                                                            value={amount}
                                                            onChange={changeamount}
                                                            aria-readonly
                                                            disabled
                                                            error={Boolean(touched.amount && errors.amount)}
                                                            helperText={touched.amount && errors.amount}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        {/* <InputLabel shrink htmlFor="circle">
                                                            Start Date</InputLabel> */}
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Plan Start Date"
                                                                value={start_date}
                                                                onChange={(newValue) => {
                                                                    setstart_date(newValue);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        {/* <InputLabel shrink htmlFor="circle">
                                                            End Date</InputLabel> */}
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Plan End Date"
                                                                value={end_date}
                                                                onChange={(newValue) => {
                                                                    setend_date(newValue);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Stack spacing={3} sx={{ mt: 4 }}>
                            <div>

                                <Button

                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Update
                                </Button>
                            </div>
                        </Stack>

                    </Form >
                </FormikProvider >
                <ToastContainer />
            </Container >
        </Page >
    );

}
