import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { Link as RouterLink, useNavigate, NavLink, useLocation, useParams } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import TextField from '@material-ui/core/TextField';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';

import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
// import Select from "react-select";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
// import FormControl from '@mui/material/FormControl';


// material
import {
    Link,
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel, Box, Grid, Container, Typography,
    InputLabel, MenuItem, FormControl, Select, Button
} from '@mui/material';

// material
import { Card, CardHeader, CardContent, Paper } from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';


export default function AddSubscriber() {
    const location = useLocation();
    const params = useParams();
    const [gallery, setgallery] = useState([])
    const [amount, setamount] = useState(0)
    const [path, setpath] = useState('')
    const [Level, setLevel] = useState('Freemium')
    const [Level_old, setLevel_old] = useState('Freemium')
    const [start_date, setstart_date] = useState(moment().toDate());
    const [end_date, setend_date] = useState(moment().add(10, 'years').toDate());
    const [start_date_old, setstart_date_old] = useState(moment().toDate());
    const [end_date_old, setend_date_old] = useState(moment().add(10, 'years').toDate());




    const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/';

    const navigate = useNavigate();
    const LoginSchema = Yup.object().shape({
    });

    const formik = useFormik({
        initialValues: {
  
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            const bodyFormData = {};
            bodyFormData.user_id = params.userId;
            bodyFormData.amount = amount;
            bodyFormData.currency_type = 'N/A';
            bodyFormData.plan_type = 'N/A';
            bodyFormData.plan_level = Level;
            bodyFormData.customer_id = 'N/A';
            bodyFormData.subscription_method = 'N/A';
            bodyFormData.invoice_id = 'N/A';
            bodyFormData.payment_status = 'active';
            bodyFormData.expireddate = moment(end_date).format('YYYY-MM-DD HH:mm:ss');
            bodyFormData.currentdate = moment(start_date).format('YYYY-MM-DD HH:mm:ss');
            const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/usersubscriptionbyadmin`;
            axios.post(Livechaturl, bodyFormData, {
                'Content-Type': 'multipart/form-data', headers: {
                    'x-access-token': localStorage.getItem('token'),
                }
            })
                .then((response) => {
                    const outlook = response
                    if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
                        navigate('/login', { replace: true });
                        localStorage.setItem("token", "");
                        localStorage.setItem("userid", '0');
                        localStorage.setItem("email", '');
                        toast.warning(outlook.data.message)
                    }
                    else {
                        navigate(`/dashboard/subscriber`, { replace: true });

                        toast.success(outlook.data.message)
                    }
                })
        }
    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
        navigate('/login', { replace: true });
    }
    // const { handleSubmit, control } = useForm();


    const changeamount = (event) => {
        setamount(event.target.value)
    }

    const handleChangeforselectlevel = (event) => {
        setLevel(event.target.value);
        if(Level_old===event.target.value){
            setstart_date(start_date_old);
            setend_date(end_date_old);
            setamount(0);
        }
        else{
        if(event.target.value==='Freemium'){
            setstart_date(moment().toDate());
            setend_date(moment().add(10, 'years').toDate())
            setamount(0);
        }
        if(event.target.value==='Premium Plus'){
            setstart_date(moment().toDate());
            setend_date(moment().add(3, 'month').toDate())
            setamount(9.99);
        }
        if(event.target.value==='Premium Access'){
            setstart_date(moment().toDate());
            setend_date(moment().add(1, 'month').toDate())
            setamount(4.99);
        }
        if(event.target.value==='Platinum Access'){
            setstart_date(moment().toDate());
            setend_date(moment().add(1, 'years').toDate())
            setamount(49.99);
        }
    }
        
    };

    return (
        <Page title="Add Subscription Details | SoundChatRadio">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                    Add Subscription Details
                    </Typography>
                </Stack>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={12} lg={12}>
                                <Card>
                                    <CardHeader title="Add Subscription Details" />
                                    <CardContent>
                                        <Grid container spacing={3}>

                                            
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel id="demo-simple-select-helper-label">Plan type </InputLabel>
                                                        <Select

                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            label="Video Type"
                                                            onChange={handleChangeforselectlevel}
                                                            value={Level}
                                                        // defaultValue = {email}

                                                        >
                                                            <MenuItem value='Freemium'>Freemium</MenuItem>
                                                            <MenuItem value='Premium Access'>Premium Access</MenuItem>
                                                            <MenuItem value='Premium Plus'>Premium Plus</MenuItem>
                                                            <MenuItem value='Platinum Access'>Platinum Access</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        <InputLabel shrink htmlFor="circle">Amount</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="amount"
                                                            type="text"
                                                            // label="Interview Title"
                                                            aria-readonly
                                                            disabled
                                                            {...getFieldProps('amount')}
                                                            value={amount}
                                                            onChange={changeamount}
                                                            error={Boolean(touched.amount && errors.amount)}
                                                            helperText={touched.amount && errors.amount}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        {/* <InputLabel shrink htmlFor="circle">
                                                            Start Date</InputLabel> */}
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Plan Start Date"
                                                                value={start_date}
                                                                onChange={(newValue) => {
                                                                    setstart_date(newValue);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Stack spacing={3}>
                                                    <FormControl>
                                                        {/* <InputLabel shrink htmlFor="circle">
                                                            End Date</InputLabel> */}
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Plan End Date"
                                                                value={end_date}
                                                                onChange={(newValue) => {
                                                                    setend_date(newValue);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Stack spacing={3} sx={{ mt: 4 }}>
                            <div>

                                <Button

                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Add
                                </Button>
                            </div>
                        </Stack>

                    </Form >
                </FormikProvider >
                <ToastContainer />
            </Container >
        </Page >
    );

}
