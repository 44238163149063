import React, { useRef, useState, useEffect } from 'react';
import { ToastContainer, toast }              from 'react-toastify';
import { Paper, Table, Stack, Avatar, Button, Checkbox, TableRow, TableBody, TableCell, Container, Typography, TableContainer, TablePagination, Dialog, Grid, Card, CardHeader, CardContent, TableHead, TextField, FormControl, Autocomplete }        from '@material-ui/core';
import AddCircleOutlineIcon                   from '@mui/icons-material/AddCircleOutline';
import { withStyles, makeStyles }             from '@material-ui/styles';
import MuiDialogContent                       from '@material-ui/core/DialogContent';
import MuiDialogActions                       from '@material-ui/core/DialogActions';

import { useFormik, Form, FormikProvider }    from 'formik';
import * as Yup                               from 'yup';
import "./swiperstyle.css";
import { trackPromise, usePromiseTracker }    from 'react-promise-tracker';
import axios                                  from 'axios';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import CancelPresentationIcon                 from '@mui/icons-material/CancelPresentation';

import { Elements }                           from "@stripe/react-stripe-js";
import { loadStripe }                         from "@stripe/stripe-js";


import Page                                   from '../components/Page';
import CheckoutForm                           from "./CheckoutForm";
import "./order-details.css"


const stripePromise = loadStripe(process.env.REACT_APP_STRIP_CUSTOMER_ID);

const { parseString } = require("xml2js");

const useStyles = makeStyles({
  tableHeader: {
  backgroundColor: '#0078d7',
  },
  tableHeaderText: {
    color     : 'white',
    fontWeight: 'bold',
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);



export default function AddOrder() {


  const navigate = useNavigate();
  const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/product/';
  // current date 
  const currentDate                            = new Date().toISOString().split('T')[0];
  const [inputDate, setInputDate]              = useState(currentDate);
  const [premiumContent, setPremiumContent]    = useState([]);
  const [selectedProduct, setSelectedProduct]  = useState("");
  const [quantity, setQuantity]                = useState(1)
  const [productImg, setProductImg]            = useState("")
  const [totalWeight, setTotalWeight]          = useState("")
  const [validpostcode, setvalidpostcode]      = useState(false)
  const [post_Code, setPostCode]               = useState(null)
  const [subtotal, setSubtotal]                = useState(0.0)
  const [deliveryfee,setdeliveryfee]           = useState(0.0)
  const [grandtotal, setGrandTotal]            = useState(0.0);
  const [total_quantity, setTotalQuantity]     = useState(0.0);


  const [paymentIntentId, setpaymentIntendId]  = useState("");
  const [show, setShow]                        = useState(false);
  const [showToast, showToastMessageone]       = useState("");
  const [options, setoptions]                  = useState("");
  const ref                                    = useRef(null);

  const handleClose = () => {
    setShow(false);
  };

  const initialData = {
    product: [],
    shipping: {},
  };

  const [state, setState] = useState(() => {
    const storedData     = localStorage.getItem('data');
    return storedData ? JSON.parse(storedData) : initialData;
  });


  // generating reference_no------------------------------ 

  function generateReferenceNumber() {
    const timestamp    = Date.now().toString(); // Get the current timestamp as a string
    const randomDigits = Math.floor(Math.random() * 10000).toString().padStart(4, '0'); // Generate a random 4-digit number
    return `${timestamp}-${randomDigits}`;
  }
  // validation ---------------------------

  const LoginSchema = Yup.object().shape({


    full_name          : Yup.string().matches(/^[A-Za-z\s]+$/,"Only letters are allowed").required('Full Name is required'),
    phone_number        : Yup.number().typeError("Only Numbers are allowed").positive("A phone number can't start with a minus").integer("A phone number can't include a decimal point").required('A phone number is required'),

    phone_number_optional: Yup.number(),
    address_1            : Yup.string().matches(/^[A-Za-z\s]+$/,"Only letters are allowed").required('Shipping Address  is required'),
    state                : Yup.string().matches(/^[A-Za-z\s]+$/,"Only letters are allowed").required('state is required'),
    city                 : Yup.string().matches(/^[A-Za-z\s]+$/,"Only letters are allowed").required('city is required'),
    country              : Yup.string().matches(/^[A-Za-z\s]+$/,"Only letters are allowed").required('country is required'),
    postcode             : Yup.number().typeError('Please enter a Valid Code').positive('Post Code must be a positive number').integer('Post Code must be an integer').required('Post Code is required'),


  });


  const formik = useFormik({
    initialValues: {
      current_date           : inputDate,
      reference_no           : generateReferenceNumber(),
      full_name              : "",
      phone_number           : "",
      phone_number_optional  : "",
      address_1              : "",
      address_2              : "",
      state                  : "",
      city                   : "",
      country                : "",
      postcode               : ""

    },
    validationSchema: LoginSchema,
    onSubmit: () => {

   if(state.product.length === 0 ){
    toast.error("Select Atleast One Item")
   }else{
      // console.log("clicked")
      const data = JSON.stringify({
        shippingaddress: {
          full_name             : values.full_name,
          phone_number          : values.phone_number,
          phone_number_optional : values.phone_number_optional,
          address_1             : values.address_1,
          address_2             : values.address_2,
          state                 : values.state,
          city                  : values.city,
          country               : values.country,
          postcode              : values.postcode,
        },
        delivery_fee            : deliveryfee,
        total_amount            : grandtotal,
        total_quantity, //             quantity 
        order_items             : state.product,
      });


      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/getpaymentintentbyadmin`,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),

        },
        data,
      };

      trackPromise(
        axios(config)
          .then((response) => {
            if (response.data.status === 400) {
              toast.error(response.data.message);
              localStorage.removeItem("token");
              localStorage.removeItem("id");
              navigate("/Login", { replace: true });
            } else if (response.data.status === 304) {
              toast.error(response.data.message);
            }else if (response.data.status === 300) {
              toast.error(response.data.message);
            }  else {
              const paymentIntent =
                response.data.paymentIntentUpdate.client_secret;
              setoptions({
                clientSecret: paymentIntent,
              });
              setpaymentIntendId(paymentIntent);
              // navigate(`/Payment/${paymentIntent}`, { replace: true });
              setShow(true);
            }
          })
          .catch((error) => {
            console.log(error);
          })
      );



        }
    }
  });

  // --------------------------------------




  const quantityHandler = (index, newQuantity) => {
    if (newQuantity >= 1) {
      setState(prevState => {
        const updatedProducts = [...prevState.product];
        if(parseFloat(newQuantity) > parseFloat(updatedProducts[index].Quantity) ){
          toast.error("Out of Stock")
          // updatedProducts[index].quantity = 0.0;
        }else{
        updatedProducts[index].quantity = newQuantity;
        }
        return {
          ...prevState,
          product: updatedProducts,
        };
      });
    }
  };



  const handleProductSelection = (event, newValue) => {

    // console.log(newValue)
    if (newValue && newValue.Quantity !== undefined && parseInt(newValue.Quantity, 10) >= 1) {
      setSelectedProduct(newValue);
      setProductImg(newValue.productimages[0].image_name);
    } else {
      toast.error("Out Of Stock");
      setSelectedProduct("");
    }
  }

  useEffect(() => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/listproduct`;
    trackPromise(
      axios.post(Livechaturl, {},
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
          }
        })
        .then((response) => {
          const outlook = response
          // console.log(response.data.data)
          if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
            navigate('/login', { replace: true });
            localStorage.setItem("token", "");
            localStorage.setItem("userid", '0');
            localStorage.setItem("email", '');
            toast.warning(outlook.data.message)
          }
          else {
            setPremiumContent(outlook.data.data);
          }
        })
    )
  }, []);
  // console.log(premiumContent)

  const ProductHandler = () => {
    const valueToCheck = selectedProduct.title; // Change this to the value you want to check
    const exists = state.product.some(item => item.title === valueToCheck);
 if(valueToCheck !== null && valueToCheck !== undefined){
    if (exists) {
      // Value exists in the array
      toast.warning("This Product is Already Exists")
    } else {
      setState(prevState => ({
        ...prevState,
        product: [...prevState.product, {
          id       : selectedProduct.id,
          title    : selectedProduct.title,
          Price    : selectedProduct.Price,
          weight   : selectedProduct.weight,
          Quantity : selectedProduct.Quantity,
          quantity,
          productImg,
        }],
      }));

    }
   }else{
    toast.error("Please Select Item")
    }
  }


  const handleRemoveItem = (index) => {
    const updatedProduct = [...state.product];
    updatedProduct.splice(index, 1);
    setState((prevState) => ({
      ...prevState,
      product: updatedProduct,
    }));
  };




  const classes = useStyles();
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  //  for product Details 
  useEffect(() => {
    localStorage.setItem('data', JSON.stringify(state));

    let totalwigth = 0.0
    for (let filterindex = 0; filterindex < state.product.length; filterindex += 1) {
      totalwigth += (parseFloat(state.product[filterindex].quantity) * parseFloat(state.product[filterindex].weight))

    }
    
    setTotalWeight(totalwigth)

    // for totalPrice formula = quantity*price
    let totalPrice = 0.0
    for (let filterindex = 0; filterindex < state.product.length; filterindex += 1) {
      totalPrice += (parseFloat(state.product[filterindex].quantity) * parseFloat(state.product[filterindex].Price))
    }
    setSubtotal(totalPrice)

    // for quantity 
    let total_quantity = 0.0
    for (let filterindex = 0; filterindex < state.product.length; filterindex += 1) {
      total_quantity += (parseFloat(state.product[filterindex].quantity))

    }
    setTotalQuantity(total_quantity)


  }, [state]);


  useEffect(()=>{
    if(post_Code){

    
    // console.log(post_Code)
    const data = JSON.stringify({
      postelcode: post_Code,
      pounds: totalWeight,
    });
    // console.log(data)
    // console.log(state.product.length)
    const config = {
      method: "post",
      url: "https://app.soundchatradio.com:3000/api/v1/auth/shippingcharge",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    trackPromise(
      axios(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          if(response.data.status===200){
          const xml = response.data.data;
          parseString(xml, (err, result) => {
            const object = result.RateV4Response.Package[0];
            // console.log(object.hasOwnProperty("Error"), "propdos");
            if ("Error" in object) {
              toast.error(result.RateV4Response.Package[0].Error[0].Description[0])
              setdeliveryfee(0);
              setvalidpostcode(false);
              // console.log(result.RateV4Response.Package[0].Error[0], "eoror");
            } else {
              // console.log(result.RateV4Response.Package[0].Postage[0].Rate[0]);
              setdeliveryfee(
                result.RateV4Response.Package[0].Postage[0].Rate[0]
              );
              setvalidpostcode(true);
              
            }
          });
        }else{
          toast.error(response.data.message)

        }
        })
        .catch((error) => {
          console.log(error);
        })
    );
      }
      else{
        setdeliveryfee(0)
      }

  },[post_Code])
  // console.log(subtotal)

  useEffect(() => {
    const newGrandTotal = (parseFloat(subtotal) + parseFloat(deliveryfee));
    setGrandTotal(newGrandTotal);
  }, [subtotal, deliveryfee]);



  return (
    <Page title="Add Order | SoundChatRadio">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom ml={2}>
            Add New Order
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
           
              <Grid item xs={12} md={12} lg={12}>
                <Card >
                  <CardHeader title="Add Order" />
                  <CardContent>
                    <Grid container spacing={3}>

                    <Grid item xs={7} sm={9} md={10} lg={10}>
                      <Stack spacing={3}>
                        <FormControl sx={{ width: '100%' }}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={premiumContent}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => <TextField {...params} label="Products" />}
                            onChange={handleProductSelection}
                          />
                        </FormControl>
                      </Stack>
                    </Grid>

                    <Grid item xs={5} sm={3} md={2} lg={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <Button
                        style={{ backgroundColor: "#0078d7", boxShadow: "none", padding: "18px", minWidth: 'auto',marginRight: "40px" }}
                        size="large"
                        type="button"
                        variant="contained"
                        onClick={ProductHandler}
                      >
                        <AddCircleOutlineIcon style={{ fontSize: '32px', color: 'white' }} />
                      </Button>
                    </Grid>
                      <Grid container spacing={3} mt={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>

                          <CardHeader title="Show Details" />
                          <CardContent>
                            <TableContainer component={Paper}>
                              <Table  className='single_line_header'>
                                <TableHead>
                                  <TableRow className={classes.tableHeader}>
                                    <TableCell className={classes.tableHeaderText}>Product Image</TableCell>
                                    <TableCell className={classes.tableHeaderText}>Product Title</TableCell>
                                    <TableCell className={classes.tableHeaderText}>Net Unit Price</TableCell>
                                    <TableCell className={classes.tableHeaderText}>Quantity</TableCell>
                                    <TableCell className={classes.tableHeaderText}>Subtotal</TableCell>
                                    <TableCell className={classes.tableHeaderText}>Total Weight</TableCell>
                                    <TableCell className={classes.tableHeaderText}>Remove</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                {state.product.length === 0 ? (
                                <TableRow>
                                  <TableCell colSpan={8} align="center" style={{background:"#ebd6d642"}}>
                                  <b style={{color:"black"}}>  No Data Available</b>
                                  </TableCell>
                                </TableRow>
                               ) : (
                                  state.product.map((detail, index) => (
                                    <TableRow key={index}>
                                      <TableCell>
                                        <div className="order-img-new">
                                          <img src={`${baseurl}${detail.productImg}`} alt="{item.product.title}" />
                                        </div>
                                      </TableCell>
                                      <TableCell>{detail.title}</TableCell>
                                      <TableCell>$ {detail.Price}</TableCell>
                                      <TableCell> <TextField style={{ width: "50px" }}
                                        label=" "
                                        size="small"
                                        color="primary"
                                        type="number"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        variant="outlined"
                                        value={detail.quantity}
                                        onChange={(e) => quantityHandler(index, e.target.value)}
                                      /></TableCell>
                                      <TableCell> <b>$</b> {parseFloat(parseFloat(detail.quantity) * parseFloat(detail.Price)).toFixed(2)}</TableCell>
                                      <TableCell>  {parseFloat(parseFloat(detail.quantity) * parseFloat(detail.weight)).toFixed(2)}</TableCell>
                                      <TableCell >
                                        <CancelPresentationIcon style={{ color: "red" }} onClick={() => handleRemoveItem(index)} />
                                      </TableCell>

                                    </TableRow>
                                  ))
                               )}
                                </TableBody>
                              </Table>
                            </TableContainer>

                          </CardContent>
                <Grid item xs={12} md={12} lg={12}>
                <Card  style={{marginLeft: "25px"}}>
                  <CardHeader title="Shipping Details" />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Stack spacing={3}>
                          <FormControl>
                            <TextField
                              fullWidth
                              autoComplete="full_name"
                              type="text"
                              label="Full name"
                              {...getFieldProps('full_name')}
                              error={Boolean(touched.full_name && errors.full_name)}
                              helperText={touched.full_name && errors.full_name}
                            />
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <Stack spacing={3}>
                          <FormControl>
                            <TextField
                              fullWidth
                              autoComplete="phone_number"
                              type="text"
                              label="Phone Number"
                              {...getFieldProps('phone_number')}
                              error={Boolean(touched.phone_number && errors.phone_number)}
                              helperText={touched.phone_number && errors.phone_number}
                            />
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <Stack spacing={3}>
                          <FormControl>
                            <TextField
                              fullWidth
                              autoComplete="phone_number_optional"
                              type="text"
                              label="Phone Number Optional"
                              {...getFieldProps('phone_number_optional')}
                              error={Boolean(touched.phone_number_optional && errors.phone_number_optional)}
                              helperText={touched.phone_number_optional && errors.phone_number_optional}
                            />
                          </FormControl>
                        </Stack>
                      </Grid>






                      <Grid item xs={12} md={4} lg={4}>
                        <Stack spacing={3}>
                          <FormControl>
                            <TextField
                              fullWidth
                              autoComplete="address_1"
                              type="text"
                              label="Address 1"
                              {...getFieldProps('address_1')}
                              error={Boolean(touched.address_1 && errors.address_1)}
                              helperText={touched.address_1 && errors.address_1}
                            />
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <Stack spacing={3}>
                          <FormControl>
                            <TextField
                              fullWidth
                              autoComplete="address_2"
                              type="text"
                              label="Address 2 "
                              {...getFieldProps('address_2')}
                              error={Boolean(touched.address_2 && errors.address_2)}
                              helperText={touched.address_2 && errors.address_2}
                            />
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <Stack spacing={3}>
                          <FormControl>
                            <TextField
                              fullWidth
                              autoComplete="state"
                              type="text"
                              label="State "
                              {...getFieldProps('state')}
                              error={Boolean(touched.state && errors.state)}
                              helperText={touched.state && errors.state}
                            />
                          </FormControl>
                        </Stack>
                      </Grid>



                      <Grid item xs={12} md={4} lg={4}>
                        <Stack spacing={3}>
                          <FormControl>
                            <TextField
                              fullWidth
                              autoComplete="city"
                              type="text"
                              label="City"
                              {...getFieldProps('city')}
                              error={Boolean(touched.city && errors.city)}
                              helperText={touched.city && errors.city}
                            />
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <Stack spacing={3}>
                          <FormControl>
                            <TextField
                              fullWidth
                              autoComplete="country"
                              type="text"
                              label="Country"
                              {...getFieldProps('country')}
                              error={Boolean(touched.country && errors.country)}
                              helperText={touched.country && errors.country}
                            />
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <Stack spacing={3}>
                          <FormControl>
                            <TextField
                              fullWidth
                              autoComplete="postcode"
                              type="text"
                              label="Post Code"
                              {...getFieldProps('postcode')}
                              error={Boolean(touched.postcode && errors.postcode)}
                              helperText={touched.postcode && errors.postcode}
                              onBlur={e => {
                                const someValue = e.currentTarget.value
                                // console.log(someValue)
                                setPostCode(someValue)
                              }}
                            // onChange={(e)=>{
                            //   console.log(e.target.value)
                            // }}
                            />
                          </FormControl>
                        </Stack>
                      </Grid>



                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
                          <Grid container spacing={3} mt={2}>
                            <Grid item xs={4} md={4} lg={4} />
                            <Grid item xs={4} md={4} lg={4} />
                            <Grid item xs={4} md={4} lg={4}>
                              <Card >
                                <CardHeader />
                                <CardContent>

                                  <Typography variant="subtitle1">Shipping Charge: <span style={{ float: "right" }}> <b>$ {parseFloat(deliveryfee).toFixed(2)} </b></span></Typography>
                                  <Typography variant="subtitle1"> Total Amount: <span style={{ float: "right" }}> <b>$  {parseFloat(subtotal).toFixed(2)}</b></span></Typography>

                                  <hr />
                                  <Typography variant="subtitle1">Grand Total: <span style={{ float: "right" }}> <b>$ {parseFloat(grandtotal).toFixed(2)} </b></span></Typography>


                                  <Stack spacing={3} mt={4}>
                                    <FormControl>
                                      {validpostcode && <Button style={{ background: "#0078d7", color: "white", boxShadow: "none" }} type="submit " variant="contained" color="primary">
                                        Checkout
                                      </Button>}
                                    </FormControl>
                                  </Stack>

                                </CardContent>
                              </Card>
                            </Grid>

                          </Grid>
                        </Grid>
                      </Grid>




                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Form>

       
        </FormikProvider >
        <ToastContainer />

        <Dialog 
          open={show}
          onClose={() => setShow(false)}
          PaperProps={{
            style: {
              width: '100%', // Adjust the width value as needed
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
          <div className="payment-box">
              {paymentIntentId ? (
                <>
                  <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm />
                  </Elements>
                </>
              ) : (
                <></>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            {/* <Button  color="error" onClick={() => setShow(false)} >X</Button> */}
         
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  )
}


