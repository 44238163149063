import { useState,useEffect } from 'react';
import axios from 'axios';
import { Link as RouterLink,useNavigate,useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// material
import {
  Stack,
  Button,
  Container,
  Typography
} from '@material-ui/core';// components

import { trackPromise,usePromiseTracker } from 'react-promise-tracker';
import { Spinner } from '../components/Spinner/index';


import Page from '../components/Page';
import {
  ProductList,
} from '../components/_dashboard/products';
//
// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const navigate = useNavigate();
  const {state} = useLocation();
  const { promiseInProgress } = usePromiseTracker();

  // const { id} = state;

  if ((localStorage.getItem('token')==='' && localStorage.getItem("userid")==='0') || (localStorage.getItem('token')===null && localStorage.getItem("userid")===null)) {
    navigate('/login', { replace: true });
}

  const [premiumContent, setPremiumContent] = useState([]);

    useEffect(() => {
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/listproduct1`;
        trackPromise(
        axios.post(Livechaturl, {},
          {
            headers: {
              'x-access-token': localStorage.getItem('token'),
            }
          })
            .then((response) => {
                const outlook = response
                if((outlook.data.status===400) || (outlook.data.status===401) || (outlook.data.status===403)){
                  navigate('/login', { replace: true });
                  localStorage.setItem("token", "");
                  localStorage.setItem("userid", '0');
                  localStorage.setItem("email", '');
                  toast.warning(outlook.data.message)
              }
              else{
                setPremiumContent(outlook.data.data);
              }
            })
        )
    }, [state]);

  return (
    <Page title="Products List | SoundChatRadio">
      <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Prodcut List
          </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/dashboard/products/addproduct"
                        startIcon={<Icon icon={plusFill} />}
                    >
                        Add Product
                        
          </Button>
                </Stack>
                {
        (promiseInProgress === true) ?
        <Spinner/>
      :
      <>
        <ProductList products={premiumContent} />
        </>
                }
        <ToastContainer />
      </Container>
    </Page>
  );
}
