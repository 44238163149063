import { useState, useEffect } from 'react';
// material
import { Container, Stack, Typography, Paper, Link, Dialog, Button, Grid, Tooltip, FormControl, Chip, Popover } from '@material-ui/core';
import axios from 'axios';
import { Link as RouterLink, useNavigate, NavLink, useLocation, useParams } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import TextField from '@mui/material/TextField';
import { useFormik, Form, FormikProvider } from 'formik';
import SendIcon from '@mui/icons-material/Send';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Image } from 'antd';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShowMoreText from 'react-show-more-text';
// import CKEditor from "react-ckeditor-component";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import msgOutline from '@iconify/icons-eva/message-square-outline';
import { Icon } from '@iconify/react';
import { withStyles } from '@material-ui/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ReactPlayer from 'react-player';
import CloseIcon from '@mui/icons-material/Close';
import playicon from '@iconify/icons-eva/play-circle-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';

import { Swiper, SwiperSlide } from "swiper/react";


import { FreeMode, Navigation, Thumbs } from "swiper";
import ReactPaginate from 'react-paginate';

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "./swiperstyle.css";
import parse from 'html-react-parser'
// components
import { Spinner } from '../components/Spinner/index';
import Page from '../components/Page';
import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar
} from '../components/_dashboard/Timeline';
//
import PRODUCTS from '../_mocks_/products';


// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  // height: 60,
  lineHeight: '60px',
  padding: '10px'
}));

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export default function TimelineImages() {
  const navigate = useNavigate();

  if ((localStorage.getItem('token') === '' && localStorage.getItem("userid") === '0') || (localStorage.getItem('token') === null && localStorage.getItem("userid") === null)) {
    navigate('/login', { replace: true });
  }

  const [openFilter, setOpenFilter] = useState(false);
  const { state,states } = useLocation();
  const params = useParams();
  const [totalCount, setTotalCount] = useState(0);
  const [gallery, setgallery] = useState([])
  const [comments, setcomments] = useState([])
  const [likes, setlikes] = useState([])
  const [galleries, setgalleries] = useState([])
  const { promiseInProgress } = usePromiseTracker();

  // const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  useEffect(() => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/timelinepostdetails`;
      axios.post(Livechaturl, { id: params.galleryId }, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      })
        .then((response) => {
          const outlook = response
          setgallery(outlook.data.data);
          // setcomments(outlook.data.data.timelinecomments)
          setTotalCount(outlook.data.data.timelinecomments.length)
          setlikes(outlook.data.data.timelinelikes)
          setgalleries(outlook.data.data.timelinemedia);
        })
  }, [params.galleryId, state])


  useEffect(() => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/listtimelinecommentsadmin`;
    axios.post(Livechaturl, { id_timeline: params.galleryId, page: currentPage, limit: 25 }, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
      }
    })
      .then((response) => {
        const outlook = response
        // setgallery(outlook.data.data);
        setcomments(outlook.data.data.comments.rows)
        // setTotalCount(outlook.data.data.comments.count)
        // setlikes(outlook.data.data.likes)
        // setgalleries(outlook.data.data.timelinemedia);
      })
  }, [params.galleryId, state])
  // We start with an empty list of items.
  //  const items = [];
  //  for (let i=1; i <= totalCount; i+1) {
  //   items.push(i);
  // }
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + 25;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(totalCount / 25));
  }, [itemOffset, totalCount]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * 25) % totalCount;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
    setCurrentPage(event.selected + 1);
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/listtimelinecommentsadmin`;
    axios.post(Livechaturl, { id_timeline: params.galleryId, page: event.selected + 1, limit: 25 }, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
      }
    })
      .then((response) => {
        const outlook = response
        // setgallery(outlook.data.data);
        setcomments(outlook.data.data.comments.rows)
        // setTotalCount(outlook.data.data.comments.count)
        // setlikes(outlook.data.data.likes)
        // setgalleries(outlook.data.data.timelinemedia);
      })
  };
  const LoginSchema = Yup.object().shape({
    // reply_message: Yup.string().required('Comment is required'),

  });

  const formik = useFormik({
    initialValues: {
      // reply_message: ""

    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      resetForm({ values: '' })
      if (content.trim()) {
        const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/addtimelinecommentsadmin`;
        axios.post(Livechaturl, {
          timeline_id: params.galleryId,
          comments: content,
          user_id: localStorage.getItem("userid")
        },
          {
            headers: {
              'x-access-token': localStorage.getItem('token'),
            }
          }).then((response) => {
            const outlook = response
            if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
              navigate('/login', { replace: true });
              localStorage.setItem("token", "");
              localStorage.setItem("userid", '0');
              localStorage.setItem("email", '');
              toast.warning(outlook.data.message)
            }
            else {
              navigate(`/dashboard/timeline/details/${params.galleryId}`, { state: { id: 'kk' } });
              toast.success(outlook.data.message)
              setcontent('')
            }
          })
      } else {
        toast.error('comment box cannot be empty')
      }
    }

  });

  const { errors, touched, values, resetForm, isSubmitting, handleSubmit, getFieldProps } = formik;

  const [value, setValue] = useState();
  const [cmdid, setCmdid] = useState();


  function handleSubmitReply(event) {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/addtimelinereplyadmin`;
    if (replycontent.trim()) {
      axios.post(Livechaturl, {
        id_comment: cmdid,
        reply: replycontent,
        user_id: localStorage.getItem("userid")
      },
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
          }
        }).then((response) => {
          const outlook = response
          if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
            navigate('/login', { replace: true });
            localStorage.setItem("token", "");
            localStorage.setItem("userid", '0');
            localStorage.setItem("email", '');
            toast.warning(outlook.data.message)
          }
          else {
            navigate(`/dashboard/timeline/details/${params.galleryId}`, { state: { id: 'kk' } });
            toast.success(outlook.data.message)
            setreplycontent('')
          }
        })
    }
    else {
      toast.error('Reply box cannot be empty')
    }
    event.preventDefault();
    setAnchorEl(null);
  }
  const handleChangereply = event => {
    setValue(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickpopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosepopover = () => {
    setAnchorEl(null);
  };

  const openpopover = Boolean(anchorEl);
  const id = openpopover ? 'simple-popover' : undefined;

  const [open, setOpen] = useState(false);
  const handleClickOpen = (type) => {
    // console.log(type)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deleterow = () => {
    handleClose()
  };
  // console.log(comments)
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const Imageurl = "https://app.soundchatradio.com/soundradiobackend/images/thumbnail/"
  const Imageurl1 = "https://app.soundchatradio.com/soundradiobackend/images/timeline/"
  const baseurl = 'https://app.soundchatradio.com/soundradiobackend/images/';

  const [open1, setOpen1] = useState(false);
  const [image_id, setimage_id] = useState('')
  const [comment_id, setcomment_id] = useState('')


  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const deleterow1 = () => {

    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/deletetimelinemedia`;
    axios.post(Livechaturl, {
      id: image_id,
    },
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      }).then((response) => {
        const outlook = response
        // alert(outlook.data.message);
        if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
          navigate('/login', { replace: true });
          localStorage.setItem("token", "");
          localStorage.setItem("userid", '0');
          localStorage.setItem("email", '');
          toast.warning(outlook.data.message)
        }
        else {
          navigate(`/dashboard/timeline/details/${params.galleryId}`, { state: { id: 'kk' } });
          setimage_id('')
          toast.success(outlook.data.message)
        }
      })
    handleClose1()
  }

  
  const handleClickdelete = (id) => {
    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/admindeletetimelinecomments`;
    axios.post(Livechaturl, {
      'comment_id': id,
      'timeline_id':params.galleryId
    },
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      }).then((response) => {
        const outlook = response
        // alert(outlook.data.message);
        if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
          navigate('/login', { replace: true });
          localStorage.setItem("token", "");
          localStorage.setItem("userid", '0');
          localStorage.setItem("email", '');
          toast.warning(outlook.data.message)
        }
        else {
          navigate(`/dashboard/timeline/details/${params.galleryId}`, { state: { id: 'kk' } });
          toast.success(outlook.data.message)
        }
      })
  }

  const Timelinelikes = () => {

    const Livechaturl = `https://app.soundchatradio.com:3000/api/v1/auth/adminaddtimelinelikes`;
    axios.post(Livechaturl, {
      timeline_id: params.galleryId,
      likes_icon_id: '1',
      user_id: localStorage.getItem("userid")
    },
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        }
      }).then((response) => {
        const outlook = response
        // alert(outlook.data.message);
        if ((outlook.data.status === 400) || (outlook.data.status === 401) || (outlook.data.status === 403)) {
          navigate('/login', { replace: true });
          localStorage.setItem("token", "");
          localStorage.setItem("userid", '0');
          localStorage.setItem("email", '');
          toast.warning(outlook.data.message)
        }
        else {
          navigate(`/dashboard/timeline/details/${params.galleryId}`, { state: { id: 'kk' } });
          toast.success(outlook.data.message)
        }
      })
    handleClose1()
  }

  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [expand, setExpand] = useState(false);
  const onClick = () => {
    setExpand(!expand);
  };
  const [content, setcontent] = useState('');
  const [replycontent, setreplycontent] = useState('');

  // useEffect((newContent)=>{
  // setcontent(newContent)
  // },[newContent])


  function onChange(evt) {
    // console.log("onChange fired with event info: ", evt);
    const newContent = evt.editor.getData();
    setcontent(newContent)
  }

  function onBlur(evt) {
    // console.log("onBlur event called with event info: ", evt);
  }

  function afterPaste(evt) {
    // console.log("afterPaste event called with event info: ", evt);
  }
  return (
    <Page title="Timeline Media Details | SoundChatRadio">
      <Container>
      {/* {
        (promiseInProgress === true) ?
        <Spinner/>
      :
      <> */}
        {
          (galleries && galleries.length > 0) ?
            <>
              <Item key={6} elevation={6} style={{ height: '60px' }}>
                <Stack spacing={2} sx={{ pb: 1, pr: 3, pl: 1 }}>

                  <Stack direction="row" alignItems="center" justifyContent="space-between">


                    <Typography variant="h4" sx={{ mb: 5 }} >
                      Post Details
                </Typography>

                    <Link onClick={Timelinelikes}>
                      <Typography variant="h4" sx={{ mb: 5 }} >
                        Likes- {likes.length}
                      </Typography>
                    </Link>
                  </Stack>
                </Stack>
              </Item>

              <Grid container spacing={3} style={{ paddingBottom: '0px' }}>
                <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '45px' }}>
                  <div style={{ height: '100%' }}>
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff"
                      }}
                      spaceBetween={10}
                      navigation={Boolean(1)}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      {(galleries.length>1)?<>
                      { galleries.map((product) => (
                        <>
                          {(product.media_thumbnail && product.media_type === 'video') ? <>
                            <SwiperSlide>
                              <h6 style={{
                                zIndex: 1, position: 'absolute', right: 0,
                                top: 0
                              }}>
                                <IconButton onClick={() => {
                                  handleClickOpen1();
                                  setimage_id(product.id);
                                }}>
                                  <Icon icon={trash2Outline} width={30} height={30} color='red' style={{}} />
                                </IconButton>

                              </h6>
                              <div className='player-wrapper'>
                                <ReactPlayer
                                  url={Imageurl1 + product.media_name}
                                  className='react-player'
                                  playing={Boolean(1)}
                                  controls={Boolean(1)}
                                  auto={Boolean(0)}
                                // width="100%"

                                />
                              </div>
                            </SwiperSlide>
                          </> : <><SwiperSlide><h6 style={{
                            zIndex: 1, position: 'absolute', right: 0,
                            top: 0
                          }}>
                            <IconButton onClick={() => {
                              handleClickOpen1();
                              setimage_id(product.id);
                            }}>
                              <Icon icon={trash2Outline} width={30} height={30} color='red' style={{}} />
                            </IconButton>

                          </h6>
                            <Image
                              rootClassName="preview_custom"
                              src={Imageurl1 + product.media_name}
                            />
                          </SwiperSlide>
                            </>
                          }
                        </>
                      ))}
                      </>:<>
                      { galleries.map((product) => (
                        <>
                          {(product.media_thumbnail && product.media_type === 'video') ? <>
                            <SwiperSlide>
                              <div className='player-wrapper'>
                                <ReactPlayer
                                  url={Imageurl1 + product.media_name}
                                  className='react-player'
                                  playing={Boolean(1)}
                                  controls={Boolean(1)}
                                  auto={Boolean(0)}
                                // width="100%"

                                />
                              </div>
                            </SwiperSlide>
                          </> : <><SwiperSlide>
                            <Image
                              rootClassName="preview_custom"
                              src={Imageurl1 + product.media_name}
                            />
                          </SwiperSlide>
                            </>
                          }
                        </>
                      ))}
                      </>}
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={Boolean(1)}
                      watchSlidesProgress={Boolean(1)}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      {galleries.map((product) => (
                        <>
                          {(product.media_thumbnail && product.media_type === 'video') ? <>
                            <SwiperSlide>
                              <Link>
                                <Icon icon={playicon} width={50} height={50} color='#c4c6c7' style={{
                                  position: 'absolute',
                                  top: '41%',
                                  left: '0',
                                  right: '0',
                                  zIndex: '999',
                                  display: 'flex',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                  margin: '0 auto'
                                }} /></Link> <ProductImgStyle alt={product} src={Imageurl + product.media_thumbnail} />
                            </SwiperSlide>
                          </> : <><SwiperSlide><ProductImgStyle alt={product} src={Imageurl1 + product.media_name} /></SwiperSlide></>
                          }
                        </>
                      ))}
                    </Swiper>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>

                  <Typography
                    sx={{ display: 'inline', fontSize: '0.8rem' }}
                    variant="h6"
                    color="text.secondary"
                  >
                    <ShowMoreText
                      lines={2}
                      more='view more'
                      less='view less'
                      onClick={onClick}
                      expanded={expand}
                      width={1000}
                    >
                      {gallery.post_desc}
                    </ShowMoreText></Typography>
                  {/* </Tooltip> */}
                </Grid>
              </Grid>
            </> : <></>}

        <List sx={{ width: '100%', bgcolor: 'background.paper', padding: 1, margin: 1 }}>
          <Typography variant="h4" noWrap style={{ paddingLeft: '60px' }}>
            Comments - {totalCount}
          </Typography>

          <Grid container spacing={2} style={{ padding: '30px' }}>
            {
              (galleries && galleries.length > 0) ?
                <>
                  <Grid xs={10} sm={10} md={10}> <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid item xs={12} md={12} lg={12} style={{ padding: '40px' }}>
                        <Stack spacing={3}>
                          <FormControl>
                            <CKEditor
                              editor={ClassicEditor}
                              data={content}
                              config={{
                                toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                                  'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']
                              }}
                              onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                // console.log('Editor is ready to use!', editor);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setcontent(data)
                                // console.log({ event, editor, data });
                              }}
                              onBlur={(event, editor) => {
                                // console.log('Blur.', editor);
                              }}
                              onFocus={(event, editor) => {
                                // console.log('Focus.', editor);
                              }}
                            />
                          </FormControl>
                        </Stack>
                        <Stack spacing={3} sx={{ mt: 1 }}>
                          <div>

                            <Button

                              size="medium"
                              type="submit"
                              variant="contained"
                              loading={isSubmitting}
                              endIcon={<SendIcon />}
                            >
                              COMMENT
                                </Button>
                          </div>
                        </Stack>
                      </Grid>
                    </Form>
                  </FormikProvider ></Grid>
                  <Grid item xs={2} sm={2} md={2}>
                    <Item />
                  </Grid>
                  {comments && comments.map((cmts, index) => (
                    <>
                      <Grid item xs={10} sm={10} md={10} >
                        <Item><ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            {(cmts.admin != null) ? <><Avatar alt="sound chat admin" src="/static/soundpic.png" /></> : <><Avatar alt={cmts.user.fname + cmts.user.lname} src={baseurl + cmts.user.profilepic} /></>
                            }

                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {(cmts.admin != null) ? <>Sound Chat Radio Admin</> : <>{cmts.user.fname}</>
                                  }
                                </Typography>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="h1"
                                  variant="body1"
                                  color="text.secondary"
                                >
                                  &nbsp;&nbsp;{moment.utc(cmts.createdAt).format('llll')}
                                  {/* moment.tz("2014-06-01 12:00", "America/New_York") */}
                                  {/* moment().format("MMM Do YY") */}
                                </Typography>

                              </>

                            }
                            secondary={
                              <>
                                <Typography
                                  sx={{ display: 'inline', fontSize: '0.8rem' }}
                                  component="p"
                                  variant="body1"
                                  color="text.secondary"
                                >
                                  <ShowMoreText
                                    lines={2}
                                    more='view more'
                                    less='view less'
                                    onClick={onClick}
                                    expanded={expand}
                                    width={700}
                                  >
                                    {parse(cmts.comments)}
                                  </ShowMoreText></Typography>
                              </>

                            }
                            style={{ backgroundColor: '#f2f1f1', padding: '10px', borderRadius: '25px' }}
                          />
                          <h6 style={{
                                zIndex: 1, position: 'absolute', right: 28,
                                top: 0
                              }}>
                                <IconButton onClick={() => {
                                  setcomment_id(cmts.id);
                                    handleClickdelete(cmts.id);
                                  // handleClickdelete();
                                   
                                }}>
                                  <Icon icon={trash2Outline} width={20} height={20} color='red' style={{}} />
                                </IconButton>

                              </h6>
                        </ListItem></Item>
                      </Grid>

                      <Grid item xs={2} sm={2} md={2}>
                        <Item />
                      </Grid>
                      <Grid item xs={10} sm={10} md={10} style={{ margin: '0px', paddingTop: '0px', paddingBottom: '0px' }}>

                        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{
                              top: '-34px',
                              right: '113px',
                              position: 'absolute'
                            }}
                          >
                            <Typography variant='button'>Reply</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <form autoComplete="off" noValidate onSubmit={(event) => {
                                handleSubmitReply(event);
                                setExpanded(false)
                                // handleChange(`panel${index}`)
                              }
                              }>
                                <Grid item xs={12} md={12} lg={12}>
                                  <Stack spacing={3}>
                                    <FormControl>
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={replycontent}
                                        config={{
                                          toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                                            'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']
                                        }}
                                        onReady={editor => {
                                          // You can store the "editor" and use when it is needed.

                                          // console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {

                                          const data = editor.getData();
                                          setreplycontent(data)
                                          setCmdid(cmts.id);
                                          // console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                          // console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                          // console.log('Focus.', editor);
                                        }}
                                      />
                                    </FormControl>
                                  </Stack>
                                  <Stack spacing={3} sx={{ mt: 4 }}>
                                    <div>

                                      <Button

                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                        endIcon={<SendIcon />}
                                      >
                                        REPLY
                                </Button>
                                    </div>
                                  </Stack>
                                </Grid>
                              </form>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} style={{ margin: '0px', paddingTop: '0px', paddingBottom: '0px' }}>
                        {(cmts.timelinereplies && cmts.timelinereplies.length > 0) ? <>
                          <Button
                            variant="span"
                            component={RouterLink}
                            to={`/dashboard/timeline/comment/reply/${params.galleryId}/${cmts.id}`}
                            style={{
                              top: '-27px',
                              right: '140px',
                              color: 'green'
                            }}
                          >
                            {cmts.timelinereplies.length} Replies

          </Button>
                        </> :
                          <></>}
                      </Grid>
                    </>
                  ))}
                </>
                : <></>}
          </Grid>
          {
            (comments && comments.length > 0) ?
              <>
                <div className="paginationnew">
                  <ReactPaginate
                    previousLabel="prev"
                    nextLabel="next"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active" />
                </div>
              </> :
              <></>}
        </List>
        <Dialog
          open={open1}
          onClose={handleClose1}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            Do You Really Want To Delete This Record ?
    </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1}>NO</Button>
            <Button onClick={deleterow1} autoFocus color="error">
              YES
      </Button>
          </DialogActions>
        </Dialog>
        
        {/* </>
} */}
<ToastContainer />
      </Container>
    </Page>
  );
}
